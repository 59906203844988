import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


//========================== upload ===============

export const uploadFile = createAsyncThunk(
  'uploadFile',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetch('http://193.203.162.247:9090/upload', {
        method: 'POST',
        body: formData, 
      });

      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        const error = await response.json();
        throw new Error(error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
//================ get file =======================

export const getFile = createAsyncThunk("getFile", async (data, { rejectWithValue }) => {
  const response = await fetch(`http://193.203.162.247:9090/download/${data.id}`, {
   
  });

  try {
    if (response.ok) {
      const imageBlob = await response.blob();

      // Create an Object URL for the image blob
      const imageUrl = URL.createObjectURL(imageBlob);
      console.log(imageUrl,"imgurlll---")
      return imageUrl;
    } else {
      return rejectWithValue('Failed to retrieve the image');
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});




const uploadDetails = createSlice({
  name: "uploadDetails",
  initialState: {
    uploads: [],
    loading: false,
    error: null,
    uploadData: [],
    Message:"",
    ImageUrl:[],

  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.loading = false;
        state.uploadData.push(action.payload);
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      //---------- Get or trad the file --------
      .addCase(getFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFile.fulfilled, (state, action) => {
        state.loading = false;
        state.ImageUrl.push(action.payload) ; // Store the image URL
      })
      .addCase(getFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
     
      
  },
});

export default uploadDetails.reducer;
