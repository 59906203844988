import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const createEmail = createAsyncThunk(
  "createEmail",
  async (data, { rejectWithValue }) => {
    const response = await fetch("http://193.203.162.247:9090/api/feedback/submitFeedback", {
      method: "POST",
      mode:"cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    console.log(result, "email res");
    return result;
  }
);

// export const showemails = createAsyncThunk(
//   "getemails",
//   async (args, { rejectWithValue }) => {
//     const response = await fetch("http://193.203.162.247:9090/emails/search");

//     try {
//       const result = await response.json();
//       // console.log(result, "reading emails");
//       return result;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );





const emailDetails = createSlice({
  name: "emailDetails",
  initialState: {
    emails: [],
    loading: false,
    error: null,
    emailsData: [], // Property for showemails
  },
  reducers: {}, // You can add additional reducers here
  extraReducers: (builder) => {
    builder
      .addCase(createEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.emails.push(action.payload);
      })
      .addCase(createEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  

    
  },
});

export default emailDetails.reducer;
