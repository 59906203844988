import React from 'react'
import {TechStack} from './Techicons'

const MyTechStack = () => {
  return (
    <div className='py-4'>
        <h1 data-aos="fade-up" data-aos-delay="400" className='text-center md:text-6xl text-4xl About_title my-4'>Technology Stack We Use </h1>
        <div className="container flex justify-center flex-wrap items-center mx-auto px-4 py-12 shadow-2xl">
          
            {TechStack.map((item)=>(
                <div key={item.id} data-aos="fade-up" data-aos-delay="500" className=' flex justify-center items-center  rounded-full p-2 m-2'> <img src={item.imgUrl} className='MytechIcons' alt={item.name} width={52} height={40} /></div>
            ))}
        </div>
    </div>
  )
}

export default MyTechStack