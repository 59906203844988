import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteTeam, showEmployee, updateTeam } from "../../utils/teamSlice";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { FcAddImage } from "react-icons/fc";
import { getFile, uploadFile } from "../../utils/uploadSlice";
const ShowEmployee = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [empId, setEmpId] = useState("");
  const [singleUser, setSingleUser] = useState({});
  const { empData } = useSelector((state) => state.team);
  const [employeeData, setEmployeeData] = useState({});
  const inputref = useRef(null);
  // const [employees, setEmployees] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const [mydownloadUrl, setmydownloadUrl] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  useEffect(() => {
    dispatch(showEmployee());
  }, [dispatch]);

  const handleEdit = async (id) => {
    setEmpId(id);
    setShowModal(true);
// filter the single user 
    const getsingleUser = empData.find((ele) => ele.id === id);
    setSingleUser(getsingleUser || {});
    console.log(getsingleUser.imgUrl, "suser");
    try {
      const result = await dispatch(getFile(getsingleUser.imgUrl));
      console.log(result,"res");
      const getdownloadUrl = result.payload.downloadURL;
      console.log(getdownloadUrl);
      setImagePreview(getdownloadUrl);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  useEffect(() => {
    const getsingleUser = empData.find((ele) => ele.id === empId);
    setSingleUser(getsingleUser || {});
  }, [empId, empData]);

  // useEffect(() => {
  //   if (singleUser && singleUser.imgUrl) {
  //     dispatch(getFile(singleUser.imgUrl))
  //       .then((result) => {
  //         const getdownloadUrl = result.payload.downloadURL;
  //         setImagePreview(getdownloadUrl);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching image:", error);
  //       });
  //   }
  // }, [singleUser]);

  const handleImageChange = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImagePreview(e.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    setProfileImage(formData);
// upload the file
    dispatch(uploadFile(formData))
      .then((result) => {
        const downloadUrl = result.payload.downloadURL; 
        setmydownloadUrl(downloadUrl);
        inputref.current.value = downloadUrl; 
        console.warn(downloadUrl, "url");  
      })
      .catch((error) => {
        console.error("Upload failed: ", error);
      });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    await dispatch(updateTeam(singleUser));
    toast.success("Employee updated");
  };
  // ===================================

  // ====================================
  return (
    <div>
      <div className="container flex flex-wrap justify-center items-center mx-auto my-4">
        {empData.map((ele) => (
          <div
            className="empbox md:w-1/3 h-48 w-full capitalize overflow-hidden hover:bg-green-500 p-4 m-4 rounded-lg shadow-xl border-2 border-gray-600"
            key={ele.id}
          >
            <h1>First Name: {ele.firstName}</h1>
            <h1>Last Name: {ele.lastName}</h1>
            <h1>Email: {ele.email}</h1>
            <h1>Phone: {ele.phone}</h1>
            <div className="flex justify-between items-center text-xl border-t-2 border-gray-600 p-2">
              <Link
                onClick={() => handleEdit(ele.id)}
                className="hover:text-yellow-500 cursor-pointer"
              >
                <AiFillEdit />
              </Link>
              <Link
                onClick={() => {
                  dispatch(deleteTeam(ele.id));
                  toast.success("Item deleted");
                }}
                className="hover:text-red-600 cursor-pointer"
              >
                <AiFillDelete />
              </Link>
            </div>
          </div>
        ))}
      </div>
      {/* -------------------------------Update Employee Model---------------------------------- */}
      {showModal && (
        <div className="fixed inset-0  flex items-center justify-center z-50 md:mt-14 mt-8">
          {/* form start */}
          <div className="myform bg-zinc-100 w-4/5 h-4/5 md:ml-0 ml-12 border p-2 md:overflow-none overflow-scroll">
            {/*header*/}
            <div className="flex items-start justify-end -pt-2 pb-1 w-full rounded-t">
              <Link
                className="p-1 ml-auto bg-transparent border-0  text-black mr-2 float-right text-6xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-4xl text-red-600  h-6 w-6  block outline-none focus:outline-none">
                  ×
                </span>
              </Link>
            </div>
            <div className="flex   flex-col justify-center items-center p-4">
              <div className="md:w-1/4 w-full flex flex-col justify-center items-center">
                <label className="text-blue border-green-500 hover:bg-blue flex md:h-40 h-24 md:w-40 w-24 cursor-pointer flex-col items-center justify-center rounded-full border bg-white uppercase tracking-wide shadow-lg hover:text-green-500">
              
                  {!profileImage ? (
                    <FcAddImage className="md:text-8xl text-6xl" />
                  ) : (
                    <div className="flex justify-center items-center">
                      <img
                        src={imagePreview}
                        alt="Profile"
                        className="rounded-full"
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  )}
                  <span className="mt-2  text-sm leading-normal">
                    {/* Upload Profile */}
                  </span>
                  <input
                    type="file"
                    name="profile"
                    id="profile"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                    // ref={inputref}
                    style={{ display: "none" }}
                  />
                </label>
              </div>
              <div className="md:w-3/4 w-full ">
                <div className=" form flex flex-wrap justify-center items-center  ">
                  <div className="p-2 md:w-1/4 w-full">
                    <div className="relative">
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={singleUser.firstName}
                        placeholder="First Name  "
                        onChange={(e) =>
                          setSingleUser({
                            ...singleUser,
                            firstName: e.target.value,
                          })
                        }
                        className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                      />
                    </div>
                  </div>
                  <div className="p-2 md:w-1/4 w-full">
                    <div className="relative">
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={singleUser.lastName}
                        placeholder="Last Name "
                        onChange={(e) =>
                          setSingleUser({
                            ...singleUser,
                            lastName: e.target.value,
                          })
                        }
                        className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                      />
                    </div>
                  </div>
                  <div className="p-2 md:w-1/4 w-full">
                    <div className="relative">
                      <input
                        type="text"
                        id="name"
                        name="email"
                        value={singleUser.email}
                        placeholder="email  "
                        onChange={(e) =>
                          setSingleUser({
                            ...singleUser,
                            email: e.target.value,
                          })
                        }
                        className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                      />
                    </div>
                  </div>
                  <div className="p-2 md:w-1/4 w-full">
                    <div className="relative">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={singleUser.phone}
                        placeholder="Contact Number  "
                        onChange={(e) =>
                          setSingleUser({
                            ...singleUser,
                            phone: e.target.value,
                          })
                        }
                        className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                      />
                    </div>
                  </div>
                  <div className="p-2 md:w-1/4 w-full">
                    <div className="relative">
                      <input
                        type="text"
                        id="designationTitle"
                        name="designationTitle"
                        value={singleUser.designationTitle}
                        placeholder="Designation"
                        onChange={(e) =>
                          setSingleUser({
                            ...singleUser,
                            designationTitle: e.target.value,
                          })
                        }
                        className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                      />
                    </div>
                    </div>
                    <div className="p-2 md:w-1/4 w-full">
                    <div className="relative">
                      <input
                        type="text"
                        id="skills"
                        name="skills"
                        value={singleUser.skills}
                        placeholder="skills"
                        onChange={(e) =>
                          setSingleUser({
                            ...singleUser,
                            skills: e.target.value,
                          })
                        }
                        className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                      />
                    </div>
                  </div>
                  <div className="p-2 md:w-1/4 w-full">
                    <div className="relative">
                      <input
                        type="email"
                        id="tagline"
                        value={singleUser.tagline}
                        name="tagline"
                        placeholder="tagline : Professional developer with an eye htmlFor detail"
                        onChange={(e) =>
                          setSingleUser({
                            ...singleUser,
                            tagline: e.target.value,
                          })
                        }
                        className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                      />
                    </div>
                  </div>
                  <div className="p-2 md:w-1/4 w-full">
                    <div className="relative">
                      <input
                        type="text"
                        id="vision"
                        name="vision"
                        value={singleUser.vision}
                        placeholder="Vision: I Enjoying Solving Problems With Clean Scalable Solutions.I Have Genuine Passion Of Aspiring Dvelopment."
                        onChange={(e) =>
                          setSingleUser({
                            ...singleUser,
                            vision: e.target.value,
                          })
                        }
                        className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                      />
                    </div>
                  </div>
                  <div className="p-2 md:w-1/4 w-full">
                    <div className="relative">
                      <input
                        type="text"
                        id="strength"
                        value={singleUser.strength}
                        name="strength"
                        placeholder="Strength : problem Solver"
                        onChange={(e) =>
                          setSingleUser({
                            ...singleUser,
                            strength: e.target.value,
                          })
                        }
                        className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                      />
                    </div>
                  </div>
                  <div className="p-2 md:w-1/4 w-full">
                    <div className="relative">
                      <input
                        type="text"
                        id="linkedin"
                        name="linkedin"
                        value={singleUser.linkedin}
                        placeholder="linked in url"
                        onChange={(e) =>
                          setSingleUser({
                            ...singleUser,
                            linkedin: e.target.value,
                          })
                        }
                        className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                      />
                    </div>
                  </div>
                  <div className="p-2 md:w-1/4 w-full">
                    <div className="relative">
                      <input
                        type="text"
                        id="github"
                        value={singleUser.github}
                        name="github"
                        placeholder="github url"
                        onChange={(e) =>
                          setSingleUser({
                            ...singleUser,
                            github: e.target.value,
                          })
                        }
                        className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                      />
                    </div>
                  </div>
                  <div className="p-2 md:w-1/4 w-full">
                    <div className="relative">
                      <input
                        type="text"
                        id="experience"
                        value={singleUser.experience}
                        placeholder="Experience in (years)"
                        name="experience"
                        onChange={(e) =>
                          setSingleUser({
                            ...singleUser,
                            experience: e.target.value,
                          })
                        }
                        className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                      />
                    </div>
                  </div>
                  <div className="p-2 md:w-1/4 w-full">
                    <div className="relative">
                      <input
                        type="text"
                        id="projects"
                        placeholder="projects ex: 6"
                        name="projects"
                        value={singleUser.projects}
                        onChange={(e) =>
                          setSingleUser({
                            ...singleUser,
                            projects: e.target.value,
                          })
                        }
                        className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                      />
                    </div>
                  </div>

                  <div className="p-2 w-full">
                    <button
                      onClick={handleUpdate}
                      className="flex mx-auto text-green-500  font-bold hover:bg-green-500 border-2 border-green-500 py-2 px-8 focus:outline-none hover:text-white rounded text-lg"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* form end  */}
        </div>
      )}
      <Toaster />
    </div>
  );
};

export default ShowEmployee;
