import React, {  useState } from "react";
import { useDispatch } from "react-redux";
import { createTestimonial } from "../../utils/testimonialSlice";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import brainLogo from '../../img/brainLogo.png'
import {  useNavigate } from "react-router-dom";


const AddTestimonial = () => {
  const [mytestimonials, setMyTestimonials] = useState({});
  const dispatch = useDispatch();
const navigate= useNavigate()
  const getTestimonialData = (e) => {
    setMyTestimonials({ ...mytestimonials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("testtimonial data...", mytestimonials);
    dispatch(createTestimonial(mytestimonials));
    toast.success("Testimonial Added");
   navigate('/admin/testimonials')
  };
  return (
    <>
      <div className="md:w-3/4  md:mx-auto mx-2 my-10">
        <div className="  shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] shadow-gray-300 rounded-lg p-6">
          <div className="w-full relative flex flex-col justify-center items-center">
            <div className=" brainLogo  absolute -top-16 w-24 h-24 rounded-full flex justify-center items-center">
              <img
                src={brainLogo}
                width={60}
                height={60}
                className="rounded-full mx-auto"
                alt="logo"
              />
            </div>
            <h1 className="text-lg text-center my-2 font-semibold">
          Add Testimonial
        </h1>
          </div>
          {/* form start */}
          <div className=" form flex flex-wrap justify-center items-center  ">
           
            <div className="p-2  w-full">
              <div className="relative">
                <input
                  type="text"
                  id="company"
                  placeholder="Client Name"
                  name="company"
                  onChange={getTestimonialData}
                  className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                />
              </div>
            </div>
            <div className="p-2  w-full">
              <div className="relative">
                <textarea
                  type="text"
                  id="feedback"
                  placeholder="Enter Your Feedback"
                  name="testimonials"
                  onChange={getTestimonialData}
                  className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                />
              </div>
            </div>
           
            <div className="p-2 w-full">
              <button
                onClick={handleSubmit}
                className="flex mx-auto text-green-500  font-bold hover:bg-green-500 border-2 border-green-500 py-2 px-8 focus:outline-none hover:text-white rounded text-lg"
              >
                Submit
              </button>
            </div>
          </div>
          {/* form end  */}
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default AddTestimonial;
