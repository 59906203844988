import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div>
      <div className="container flex text-center  flex-col justify-center items-center my-8 mx-auto">
        <div className=" w-3/4 border-2 rounded-2xl pt-4 pb-12 shadow-lg shadow-gray-700  border-gray-600 ">
          <h1 className="md:text-8xl text-4xl About_title mt-16">404</h1>
          <h1 className="md:text-6xl text-4xl About_title mt-4">
            Page Not Found
          </h1>
          <Link
            to={"/"}
            className="text-2xl font-semibold my-6 text-green-500 underline"
          >
            Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error;
