import React, { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { PiUsersThreeBold } from "react-icons/pi";
import { TbBrandBlogger } from "react-icons/tb";

import { TbTextPlus,TbTextCaption } from "react-icons/tb";
import { AiOutlineClose, AiOutlineMenu,AiOutlineUserAdd } from "react-icons/ai";
 import {RiAdminLine} from 'react-icons/ri'
 import {TfiWrite} from 'react-icons/tfi'


// import brainLogo from "../../img/brainLogo.png";

const Admin = () => {
  const [sideNav,setSideNav] = useState(false)
  const location = useLocation();
  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const HandleSideNav = () => {
    setSideNav(!sideNav);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  return (
    <>
      <section className={` body-font relative`}>
        <div className="  py-24 mx-auto">
          <div className="flex   w-full mb-12">
            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave } className={isHovered ? "w-72 border-r-2 min-h-screen text-lg -my-20 overflow-hidden ease-in duration-200" : `w-16 text-sm border-r-2 min-h-screen -my-20 overflow-hidden transition-all ease-in duration-200`}>
              
              <ul className="px-2 pt-24 break-normal">
                <li>
                   {/* Mobile Button */}
          <div onClick={HandleSideNav} className="   text-2xl flex justify-center items-center font-bold   z-10 mb-2 -mt-4">
            {isHovered ? <AiOutlineClose size={40} /> : <AiOutlineMenu size={40} />}
          </div>
          {/* Mobile Menu */}
                </li>
                <Link to={"/admin/employees/add"}>
                  <li
                    className={`md:text-xl text-lg hover:bg-green-500 hover:text-white  px-4 py-4 rounded-lg mb-2 ${isActive(
                      "/admin/employees"
                    )}`}
                  >
                    <div className="flex justify-start -ml-2 items-center">
                      {isHovered ? <div className="flex  items-center">
                      <span className="mr-2 text-2xl">
                        <AiOutlineUserAdd />
                      </span>{" "}
                      <span className="text-lg">Add Employee</span>
                    </div> : <span className="mr-2 text-2xl">
                        <AiOutlineUserAdd />
                      </span>}
                      
                    </div>
                  </li>
                </Link>
                <Link to={"/admin/employees"}>
                  <li
                    className={`md:text-xl text-lg hover:bg-green-500 hover:text-white  px-4 py-4 rounded-lg mb-2 ${isActive(
                      "/admin/employees"
                    )}`}
                  >
                    <div className="flex justify-start -ml-2 items-center">
                      {isHovered ? <div className="flex  items-center">
                      <span className="mr-2 text-2xl">
                        <PiUsersThreeBold />
                      </span>{" "}
                      <span className="text-lg">Show Employees</span>
                    </div> : <span className="mr-2 text-2xl">
                        <PiUsersThreeBold />
                      </span>}
                      
                    </div>
                  </li>
                </Link>
                {/* <Link to={"/admin/employee/add"}>
                  <li className="md:text-xl text-lg   hover:bg-green-500 hover:text-white  px-4 py-4 rounded-lg mb-2 ">
                  <div className="flex justify-start -ml-2 items-center">
                      {isHovered ? <div className="flex  items-center">
                      <span className="mr-2 text-2xl">
                        <FiUserPlus />
                      </span>{" "}
                      <span className="text-lg">Add Employee</span>
                    </div> : <span className="mr-2 text-2xl">
                        <FiUserPlus />
                      </span>}
                      
                    </div>
                  </li>
                </Link> */}
                <Link to={"/admin/testimonials"}>
                  <li className="md:text-xl text-lg   hover:bg-green-500 hover:text-white  px-4 py-4 rounded-lg mb-2 ">
                  <div className="flex justify-start -ml-2 items-center">
                      {isHovered ? <div className="flex  items-center">
                      <span className="mr-2 text-2xl">
                        <TbTextCaption />
                      </span>{" "}
                      <span className="text-lg">Show Testimonials</span>
                    </div> : <span className="mr-2 text-2xl">
                        <TbTextCaption />
                      </span>}
                      
                    </div>
                  </li>
                </Link>
                <Link to={"/admin/testimonials/add "}>
                  <li className="md:text-xl text-lg w-full  hover:bg-green-500 hover:text-white  px-4 py-4 rounded-lg mb-2">
                  <div className="flex justify-start -ml-2 items-center">
                      {isHovered ? <div className="flex  items-center">
                      <span className="mr-2 text-2xl">
                        <TbTextPlus />
                      </span>{" "}
                      <span className="text-lg">Add Testimonial</span>
                    </div> : <span className="mr-2 text-2xl">
                        <TbTextPlus />
                      </span>}
                      
                    </div>
                  </li>
                </Link>
                <Link to={"/admin/blogs/add "}>
                  <li className="md:text-xl text-lg w-full  hover:bg-green-500 hover:text-white  px-4 py-4 rounded-lg mb-2">
                  <div className="flex justify-start -ml-2 items-center">
                      {isHovered ? <div className="flex  items-center">
                      <span className="mr-2 text-2xl">
                        <TfiWrite />
                      </span>{" "}
                      <span className="text-lg">Add Blog</span>
                    </div> : <span className="mr-2 text-2xl">
                        <TfiWrite />
                      </span>}
                      
                    </div>
                  </li>
                </Link>
                <Link to={"/admin/blogs "}>
                  <li className="md:text-xl text-lg w-full  hover:bg-green-500 hover:text-white  px-4 py-4 rounded-lg mb-2">
                  <div className="flex justify-start -ml-2 items-center">
                      {isHovered ? <div className="flex  items-center">
                      <span className="mr-2 text-2xl">
                        <TbBrandBlogger />
                      </span>{" "}
                      <span className="text-lg">All Blogs</span>
                    </div> : <span className="mr-2 text-2xl">
                        <TbBrandBlogger />
                      </span>}
                      
                    </div>
                  </li>
                </Link>
                <Link to={"/admin/register "}>
                <li className="md:text-xl text-lg w-full  hover:bg-green-500 hover:text-white  px-4 py-4 rounded-lg mb-2">
                  <div className="flex justify-start -ml-2 items-center">
                      {isHovered ? <div className="flex  items-center">
                      <span className=" text-2xl">
                        <RiAdminLine />
                      </span>{" "}
                      <span className="text-lg">Add Admin</span>
                    </div> : <span className=" text-2xl">
                        <RiAdminLine />
                      </span>}
                      
                    </div>
                  </li>
                </Link>
              </ul>
            </div>

            <div className="w-full">
              <h1 className="text-center py-2 mx-auto text-3xl font-semibold About_title shadow-lg">
                Admin Panel
              </h1>
              
              <Outlet />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Admin;
