import React, { useEffect } from "react";

import "../App.css";
import Brain from "../img/brainLogo.png";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";

const Hero = () => {
  useEffect(() => {
    AOS.init({
      offset: 100,
    });
  }, []);

  return (
    <>
      <div 
     
       className="heroSectin h-screen flex md:flex-row flex-col justify-center items-center -mt-6">
        
        <div   className=" md:w-1/2 w-full h-screen bg-white  heroSection1  flex  md:justify-end justify-center items-center text-black">
          <div className="maintitle  ">
            <div className="md:w-1/2 pl-4 md:py-0 py-16 md:mt-0 mt-14">
              <h1
             
                data-aos="fade-up"
                data-aos-delay="600"
                className="text-7xl About_title md:text-left text-center md:py-0 py-8"
              >
                RE-LIVE YOUR IDEAS
              </h1>
              <div
                data-aos="fade-up"
                data-aos-delay="700"
                className="flex justify-start my-4 mx-4"
              >
                <button className="bg-green-500 hover:green-600 md:visible hidden  text-black font-semibold px-8 py-4 ">
                  sample Button{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 w-full h-screen heroSection2 bg-green-500    flex md:justify-end justify-center items-center">
          <div
            data-aos="fade-up"
            data-aos-delay="700"
            className="secondbox md:w-1/2 w-full text-white text-xl font-semibold  md:pr-4 md:p-4 p-4 md:mr-6 "
          >
            <p>
            Welcome to MonsterBrains, where innovation transforms into tangible solutions and dreams converge with reality. Our commitment to delivering affordable, reliable, and efficient IT services sets us apart in the technology landscape. Explore how our expertise can elevate your business in the digital world.
            </p>
          </div>
        </div>
      </div>
      <div    className="imageSection md:flex  justify-center items-center md:visible hidden">
          <motion.img
           initial={{ opacity: 0, scale: 0.5 }}
           animate={{ opacity: 1, scale: 1 }}
           transition={{
             duration: 0.3,
             ease: [0, 0.71, 0.2, 1.01],
             scale: {
               type: "spring",
               damping: 5,
               stiffness: 100,
               restDelta: 0.001
             }
           }}
            src={Brain}
            alt="brain Logo"
            className="absolute md:top-52 top-96 md:w-96 md:h-96 w-44 h-44 mainPageLogo"
            width={500}
            height={400}
          />
          
          {/* <h1 className="font-bold md:text-8xl text-6xl absolute bottom-8 mr-28">
          {" "}
          <span data-aos="fade-up" data-aos-delay="600" className=" text-green-500">
            MONSTER
          </span>{" "}
          <span data-aos="fade-up" data-aos-delay="900" className={``}>
            BRAINS
          </span>{" "}
        </h1>    */}
             </div>
    </>
  );
};

export default Hero;
