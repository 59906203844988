import React, { useEffect, useState } from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteBlog, showBlog, updateBlog } from "../../utils/blogSlice";

const AllBlogs = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [blogId, setBlogId] = useState("");
  const { blogData } = useSelector((state) => state.blog);
  const [editedBlog, setEditedBlog] = useState({});
  const [toggle, setToggle] = useState(false);
  const toggleClass = " transform translate-x-5";



  useEffect(() => {
    // Fetch blog data when the component mounts and when an update/delete occurs
    dispatch(showBlog());
  }, [dispatch]);


  const handleEdit = (id) => {
    const blogToEdit = blogData.find((ele) => ele.id === id);
    setBlogId(id);
    setEditedBlog({ ...blogToEdit });
    setShowModal(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    // Update the blog based on the editedBlog state
    await dispatch(updateBlog({ ...editedBlog }));
    console.warn(blogId)

    toast.success("Blog updated");
    setShowModal(false);
  };

  return (
    <div className="pt-2 min-h-screen">
      <h1 className="text-center font-bold text-4xl my-2">All Blogs</h1>
      <div className="container flex flex-wrap justify-center mx-auto my-2">
        {blogData.map((ele) => (
          <div
            key={ele.id}
            className="empbox md:w-1/3 h-54 w-full capitalize overflow-hidden hover:bg-green-500 p-4 m-4 rounded-lg shadow-xl border-2 border-gray-600"
          >
            <h1 className="text-lg">Title: {ele.title}</h1>
            <h1 className="text-lg">Author: {ele.author}</h1>
            <h1 className="text-lg">Genre: {ele.genre}</h1>
            <h1 className="text-lg">Technology: {ele.technology}</h1>
            <h1 className="text-lg">URL: {ele.url}</h1>
            <div className="flex justify-between items-center text-xl mt-4 border-t-2 border-gray-600 p-2">
              <Link
                onClick={() => {
                  handleEdit(ele.id);
                }}
                className="hover:text-yellow-500 cursor-pointer"
              >
                <AiFillEdit />
              </Link>
              <Link
                onClick={() => {
                  dispatch(deleteBlog(ele.id));
                  toast.success("Blog deleted");
                }}
                className="hover:text-red-600 cursor-pointer"
              >
                <AiFillDelete />
              </Link>
            </div>
          </div>
        ))}
      </div>
      <div>
        {showModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="relative  w-3/4 max-w-3xl bg-white rounded-lg shadow-lg">
              <div className="border-0 rounded-t">
                <button
                  className="p-1 ml-auto text-4xl leading-none font-semibold"
                  onClick={() => setShowModal(false)}
                >
                  ×
                </button>
              </div>
              <div className="p-4">
                <h2 className="text-2xl font-bold mb-4">{editedBlog.title}</h2>
                <form onSubmit={handleUpdate}>
                  <div className="mb-4">
                    <input
                      type="text"
                      id="title"
                      name="title"
                      placeholder="Blog Title"
                      value={editedBlog.title}
                      onChange={(e) =>
                        setEditedBlog({ ...editedBlog, title: e.target.value })
                      }
                      className="w-full border-b text-green-500 border-gray-600 outline-none text-lg py-2 px-1"
                    />
                  </div>
                  <div className="mb-4">
                    <input
                      type="text"
                      id="author"
                      name="author"
                      placeholder="Author Name"
                      value={editedBlog.author}
                      onChange={(e) =>
                        setEditedBlog({ ...editedBlog, author: e.target.value })
                      }
                      className="w-full border-b text-green-500 border-gray-600 outline-none text-lg py-2 px-1"
                    />
                  </div>
                  <div className="mb-4">
                    <input
                      type="text"
                      id="genre"
                      name="genre"
                      placeholder="Genre of Post"
                      value={editedBlog.genre}
                      onChange={(e) =>
                        setEditedBlog({ ...editedBlog, genre: e.target.value })
                      }
                      className="w-full border-b text-green-500 border-gray-600 outline-none text-lg py-2 px-1"
                    />
                  </div>
                  <div className="mb-4">
                    <input
                      type="text"
                      id="technology"
                      name="technology"
                      placeholder="Technology"
                      value={editedBlog.technology}
                      onChange={(e) =>
                        setEditedBlog({ ...editedBlog, technology: e.target.value })
                      }
                      className="w-full border-b text-green-500 border-gray-600 outline-none text-lg py-2 px-1"
                    />
                  </div>
                  <div className="mb-4">
                    <textarea
                      type="text"
                      id="description"
                      name="description"
                      placeholder="Write Here ..."
                      value={editedBlog.description}
                      onChange={(e) =>
                        setEditedBlog({ ...editedBlog, description: e.target.value })
                      }
                      className="w-full border-b text-lg text-green-500 border-gray-600 outline-none px-1"
                    />
                  </div>
                  <div className="mb-4">
                    <input
                      type="text"
                      id="url"
                      name="url"
                      placeholder="Reference if any"
                      value={editedBlog.url}
                      onChange={(e) =>
                        setEditedBlog({ ...editedBlog, url: e.target.value })
                      }
                      className="w-full border-b text-green-500 border-gray-600 outline-none text-lg py-2 px-1"
                    />
                  </div>
                  <div className="mb-4 flex text-green-500 items-center">
                    <h1 className="mr-2 text-lg">Published</h1>
                    <div
                      className="w-12 h-6 flex items-center text-green-500 bg-zinc-300 rounded-full p-1 cursor-pointer"
                      onClick={() => {
                        setToggle(!toggle);
                        setEditedBlog({ ...editedBlog, published: toggle });
                      }}
                    >
                      <div
                        className={
                          "w-6 h-6 rounded-full shadow-md text-green-500 bg-green-500 transform duration-300 ease-in-out" +
                          (toggle ? null : toggleClass)
                        }
                      ></div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <button
                      type="submit"
                      className="text-green-500 font-bold hover:bg-green-500 border-2 border-green-500 py-2 px-8 focus:outline-none hover:text-white rounded text-lg"
                    >
                      Update Blog
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
      <Toaster />
    </div>
  );
};

export default AllBlogs;
