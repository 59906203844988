import React, { useState } from "react";

import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";
import { colorScale, countries, missingCountries } from "../components/Countries";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { createEmail } from "../utils/emailSlice";
import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from "react-hot-toast";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";

const Contact = () => {
  const dispatch = useDispatch();
  const [myEmailData, setMyEmailData] = useState({});

  const getEmailDetails = (e) => {
    setMyEmailData({ ...myEmailData, [e.target.name]: e.target.value });
  };
  const handleEmail = (e) => {
    e.preventDefault();
    dispatch(createEmail(myEmailData));
    console.log(myEmailData, "email Data");
    toast.success("Email Sent Successfully");
  };

  return (
    <div
      id="contact"
      className={` contact  pt-24 pb-12 relative contactfield overflow-hidden px-4`}
    >
      <h1
        data-aos="fade-up"
        data-aos-delay="400"
        className="text-center md:text-6xl text-5xl mt-4 mb-2 About_title"
      >
        Wanna Get in Touch ?
      </h1>
      <div className="small"   data-aos="fade-up"
        data-aos-delay="400">
      <p className="text-center my-2">Don't miss out on the opportunity to transform your business with cutting-edge IT solutions.</p>
      <p className="text-center my-2">Contact us now and take the first step towards technological empowerment</p>

      </div>
      <div
        data-aos="fade-up"
        data-aos-delay="400"
        className="mycircle md:w-52 md:h-52 w-32 h-32 opacity-60 absolute -top-8 right-4 bg-zinc-900 rounded-full"
      ></div>
      {/* ------------------- contact details cards ------------------------ */}
      <div className="container  About_title flex justify-around md:flex-row flex-col items-center mt-10">
        <div
          data-aos="fade-up"
          data-aos-delay="400"
          className={` border-zinc-600 md:w-1/4 w-full mb-2 shadow-lg  border-2 p-4  `}
        >
          <div className="flex justify-start items-center">
            <span className="mr-2 text-2xl text-green-500">
              <AiOutlineMail />
            </span>{" "}
            info@monsterbrains.in
          </div>{" "}
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="500"
          className={`  border-zinc-600 md:w-1/4 w-full mb-2 shadow-lg  border-2 p-4 `}
        >
          <div className="flex justify-start items-center">
            <span className="mr-2 text-xl text-green-500">
              <FiPhoneCall />
            </span>
            +91 8791004100
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="500"
          className={`  border-zinc-600 md:w-1/4 w-full mb-2 shadow-lg  border-2 p-4 `}
        >
          <div className="flex justify-start items-center">
            <span className="mr-2 text-2xl text-green-500">
              <HiOutlineLocationMarker />
            </span>
             West Arjun Nagar,Agra,282001
          </div>
        </div>
       
      </div>
      <div className="container  mx-auto  py-8">
        {/* ------------------- Contact form ------------------------ */}
        <div
          data-aos="fade-up"
          data-aos-delay="500"
          className="formSectionn py-3"
        >
          {/* <h1
            data-aos="fade-up"
            data-aos-delay="500"
            className={` titles text-center py-8 text-2xl  `}
          >
           Come for Coffee at <span className="animate-bounce">👇🏼</span>
          </h1> */}
          <div className="   flex justify-center items-center md:flex-row flex-col">
            <div></div>
            <div
              data-aos="fade-up"
              data-aos-delay="500"
              className="flex justify-center items-center md:w-1/2 w-full my-4 text-center "
            >
              <h1 className={` contactHeading text-8xl font-bold `}>
                {" "}
                <span>Let&apos;s</span> <br />
                <span>Connect.</span>{" "}
              </h1>
              <h1 className=" text-4xl font-extrabold text-green-500">
                <BsArrowRight />
              </h1>
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="500"
              className="md:w-1/2 w-full  "
            >
              <div className="form px-4">
                <form>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="500"
                    className=" w-full mb-4"
                  >
                    <input
                      type="text"
                      name="name"
                      className=" w-full border-b-2 bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-xl py-4 px-1"
                      placeholder=" Enter Your Name"
                      onChange={getEmailDetails}
                    />
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="600"
                    className=" w-full  mb-4"
                  >
                    <input
                      type="email"
                      name="email"
                      className="w-full  border-b-2 bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-xl py-4 px-1"
                      placeholder=" Enter Your Email"
                      onChange={getEmailDetails}
                    />
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="600"
                    className=" w-full mx-auto mb-4"
                  >
                    <input
                      type="text"
                      name="contactNumber"
                      className=" w-full border-b-2 bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-xl py-4 px-1"
                      placeholder=" Enter Your Phone"
                      onChange={getEmailDetails}
                    />
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="600"
                    className=" w-full mx-auto mb-4"
                  >
                    <textarea
                      type="text"
                      cols={24}
                      className=" w-full  border-b-2 bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-xl py-4 px-1 "
                      name="message"
                      onChange={getEmailDetails}
                      placeholder=" Enter Your Message"
                    />
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="600"
                    className=" mx-auto mb-4 flex justify-center"
                  >
                    <Link
                      href={"/"}
                      className="text-green-500 hover:bg-green-500 hover:text-white border-2  border-green-500 text-2xl px-4 py-2 rounded  flex justify-start items-center"
                      onClick={handleEmail}
                    >
                      Send{" "}
                      <span className="px-4">
                        {" "}
                        <BsArrowRight />
                      </span>{" "}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------- map section ------------------------ */}
        <div
          data-aos="fade-up"
          data-aos-delay="500"
          className="mapSection  md:px-0 rounded-xl my-8  "
        >
          <div
            data-aos="fade-up"
            data-aos-delay="500"
            className={`titles text-center py-8 text-2xl flex justify-center items-center`}
          >
         
            <h1>Or Come For Coffee At</h1>
            <h2 className="transition-all mx-2 ease-in-out duration-300 animate-bounce">
              👇🏼
            </h2>
          </div>

          <div className="maparea w-full  bg-gray-850 mx-auto p-4 overflow-hidden rounded-lg ">
            <div
              className="myakhandbharat"
              style={{
                width: "100%",
                height: "40vh",
                scale: 2,
                marginLeft: "0px",
                borderRadius: "20px",
                background: "#18181b",
              }}
            >
              <VectorMap
                map={worldMill}
                containerStyle={{
                  width: "500px",
                  height: "400px",
                  backgroundColor: "#18181b	",
                }}
                backgroundColor="#18181b"
                markers={missingCountries}
                markerStyle={{
                  initial: {
                    fill: "red",
                  },
                }}
                series={{
                  regions: [
                    {
                      scale: colorScale,
                      values: countries,
                      min: 0,
                      max: 100,
                    },
                  ],
                }}
                onRegionTipShow={function reginalTip(event, label, code) {
                  return label.html(`
                  <div style=" color: white"; >
                    <p>
                    <b>
                    ${label.html()}
                    </b>
                    </p>
                    <p>
                    
                    </p>
                    </div>`);
                }}
                onMarkerTipShow={function markerTip(event, label, code) {
                  return label.html(`
                  <div style="background-color: ivory; border-radius: 8px; min-height: 50px; width: 125px; color: black !important; padding-left: 10px>
                    <p style="color: black !important;">
                    <b>
                    ${label.html()}
                    </b>
                    </p>
                    </div>`);
                }}
              />
            </div>{" "}
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default Contact;
