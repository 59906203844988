import React, {  useState } from "react";
import  { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import brainLogo from '../../img/brainLogo.png'
import { registerUser } from "../../utils/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

const Register = () => {
    
    const [myRegisterData, setmyRegisterData] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);
    const [MyError, setMyError] = useState("");
  
    const getRegisterDetails = (e) => {
      setmyRegisterData({ ...myRegisterData, [e.target.name]: e.target.value });
    };

    const isPasswordValid = (password) => {
      const passwordRegex =
        /^(?=.*[A-Z])(?=.*[@$!%*?&])(?=.*\d)[A-Za-z\d@$!%*?&]{5,}$/;
      return passwordRegex.test(password);
    };

    const handleRegister = (e) => {
      e.preventDefault();
       // Validate username and password
    if (!myRegisterData.username && !myRegisterData.password && myRegisterData.email ) {
      setMyError("All fields are required.");
      return;
    }
    if (!myRegisterData.email) {
      setMyError("Email is required.");
      return;
    }
    if (!myRegisterData.username) {
      setMyError("Username is required.");
      return;
    }

    if (myRegisterData.username.length < 5) {
      setMyError("Username must be at least 5 characters.");
      return;
    }

    if (!myRegisterData.password) {
      setMyError("Password is required.");
      return;
    }

    if (!isPasswordValid(myRegisterData.password)) {
      setMyError("Password is invalid.");
      return;
    }
      console.log("register data...", myRegisterData);
      dispatch(registerUser(myRegisterData));
      // toast.success("register Successfully");
      navigate('/login')

    };

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
  
  return (
    <>
    <div className="md:w-1/2 w-3/4 mx-auto pt-8">
      <div className="  shadow-[0px_4px_16px_rgba(17,17,26,10),_0px_8px_24px_rgba(17,17,26,10),_0px_16px_56px_rgba(17,17,26,10)] shadow-gray-300 rounded-lg p-6">
        <div className="w-full relative flex flex-col justify-center items-center">
          <div className=" brainLogo  absolute -top-16 w-24 h-24 rounded-full flex justify-center items-center">
            <img
              src={brainLogo}
              width={60}
              height={60}
              className="rounded-full mx-auto"
              alt="logo"
            />
          </div>
          <h1 className="text-lg text-center my-2 font-semibold">
        Register
      </h1>
        </div>
        {/* form start */}
        <div className=" form flex flex-wrap justify-center items-center  ">
        <p className="text-red-500 my-1">{MyError}</p>
         
        <div className="p-2  w-full">
            <div className="relative">
              <input
                type="email"
                id="email"
                placeholder="Enter Your Email"
                name="email"
                onChange={getRegisterDetails}
                className=" w-full border-b-2 bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
              />
            </div>
          </div>
          <div className="p-2  w-full">
            <div className="relative">
              <input
                type="text"
                id="name"
                placeholder="Enter User Name"
                name="username"
                onChange={getRegisterDetails}
                className=" w-full border-b-2 bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
              />
            </div>
          </div>
          
          <div className="p-2  w-full">
            <div className="relative flex justify-center items-center">
             <div className="w-full">
             
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Enter Your Password"
                onChange={getRegisterDetails}
                className="w-full border-b-2 bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
              />
              </div>
              <div className="w-1/6 absolute -right-4 flex justify-center items-center"
              ><span
                onClick={togglePasswordVisibility}
                className="absolute  text-2xl  cursor-pointer text-green-500"
              >
                {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </span></div>
              
            </div>
          </div>
         
          <div className="p-2 w-full">
            <button
              onClick={handleRegister}
              className="flex mx-auto text-green-500  font-bold hover:bg-green-500 border-2 border-green-500 py-2 px-8 focus:outline-none hover:text-white rounded text-lg"
            >
              Register
            </button>
          </div>
        </div>
        {/* form end  */}
      </div>
    </div>
    <Toaster />
  </>
  )
}

export default Register