import React from 'react'
import ProjectList from '../components/ProjectList'

const Work = () => {
  return (
    <div id='work'>
      <ProjectList />
    </div>
  )
}

export default Work