import React from "react";
import { Link } from "react-router-dom";

const SingleProject = ({ index, title, setModal, category, projectUrl }) => {
  return (
    <>
      <Link to={projectUrl}>
        
        <div>
          <div
            data-aos="fade-up"
            data-aos-delay="500"
            className="transition-all hover:translate-x-4 ease-in 0.3s hover:opacity-60 cursor-pointer py-4 flex justify-between items-center flex-row"
            onMouseEnter={() => {
              setModal({ active: true, index });
            }}
            onMouseLeave={() => {
              setModal({ active: false, index });
            }}
          >
            <h1 className="font-sembold">{title}</h1>
            <p className="text-lg capitalize">{category}</p>
          </div>
          <hr data-aos="fade-up" data-aos-delay="500" />
        </div>
      </Link>
    </>
  );
};

export default SingleProject;
