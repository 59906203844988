import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showBlog } from "../utils/blogSlice";

const Blogs = () => {
  const dispatch = useDispatch();
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const { blogData } = useSelector((state) => state.blog);

  useEffect(() => {
    dispatch(showBlog());
  }, [dispatch]);

  useEffect(() => {
    // Filter blogs with published set to true
    if (!blogData || !Array.isArray(blogData)) {
      // If blogData is not an array or is null/undefined
      setFilteredBlogs([]);
    } else {
      const filteredBlogs = blogData.filter((ele) => ele.published === true);
      setFilteredBlogs(filteredBlogs);
    }
  }, [blogData]);

  return (
    <div className="pt-20 min-h-screen">
      <div className="box w-3/4 mx-auto p-4">
        <h1 className="text-center font-bold text-4xl my-2"> Blogs</h1>
      <p className="text-center my-2">
        Dive into our blog for insights on the latest in IT development,
        practical tips for implementing effective IT solutions, and trends
        shaping the future of technology. Our experts share valuable knowledge
        that can help you navigate the ever-evolving tech landscape.
      </p>
      </div>
      
      <div className="container flex flex-wrap justify-center mx-auto my-2">
        {filteredBlogs.length === 0 ? (
          <h1 className="my-20 text-4xl">No blogs available</h1>
        ) : (
          filteredBlogs.map((ele) => (
            <div
              key={ele.id}
              className="empbox md:w-1/3 h-54 w-full capitalize overflow-hidden p-4 m-4 rounded-lg shadow-xl border-2 border-gray-600"
            >
              <div className="blogContent">
                <h1 className="text-lg text-center font-bold">{ele.title}</h1>
                <p>
                  {" "}
                  <span className="bg-green-500 rounded-xl px-2  ">Author</span>{" "}
                  {ele.author}
                </p>
                <p>
                  {" "}
                  <span className="bg-green-500 rounded-xl px-2  ">Genre</span>{" "}
                  {ele.genre}
                </p>
                <p>
                  {" "}
                  <span className="bg-green-500 rounded-xl px-2  ">Technology</span>{" "}
                  {ele.technology}
                </p>
                <p className="">{ele.description}</p>
                <p>
                  {" "}
                  <span className="bg-green-500 rounded-xl px-2  ">Reference</span>{" "}
                  {ele.url}
                </p>
              </div>
              <div className="flex justify-center items-center text-lg mt-4 border-t-2 border-gray-600 p-2">
                <Link
                  to={`/blogs/${ele.id}`}
                  className="text-green-500 underline cursor-pointer"
                >
                  Read Blog
                </Link>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Blogs;
