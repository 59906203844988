import React, { useState } from "react";

import "react-toastify/dist/ReactToastify.css";
import brainLogo from "../../img/brainLogo.png";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../utils/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

const Login = () => {
  const [myLoginData, setMyLoginData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [MyError, setMyError] = useState("");
  const { loading, Message } = useSelector((state) => state.auth);

  const getLoginDetails = (e) => {
    setMyLoginData({ ...myLoginData, [e.target.name]: e.target.value });
  };
  const isPasswordValid = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[@$!%*?&])(?=.*\d)[A-Za-z\d@$!%*?&]{5,}$/;
    return passwordRegex.test(password);
  };
  const handleLogin = async (e) => {
    e.preventDefault();

    // Validate username and password
    if (!myLoginData.username && !myLoginData.password) {
      setMyError("Both fields are required.");
      return;
    }
    if (!myLoginData.username) {
      setMyError("Username is required.");
      return;
    }

    if (myLoginData.username.length < 5) {
      setMyError("Username must be at least 5 characters.");
      return;
    }

    if (!myLoginData.password) {
      setMyError("Password is required.");
      return;
    }

    if (!isPasswordValid(myLoginData.password)) {
      setMyError("Password is invalid.");
      return;
    }

    // dispatch(loginUser(myLoginData)).then((resultAction) => {
    //   if (loginUser.fulfilled.match(resultAction)) {
    //     // Authentication succeeded, navigate to the admin route
    //     navigate("/admin");
    //   } else if (loginUser.rejected.match(resultAction)) {
    //     // Authentication failed, display an error message
    //     setMyError("Authentication failed.");
    //   }
    // });
    // ==========================
    // dispatch(loginUser(myLoginData)).then((resultAction) => {
    //   if (resultAction.type === loginUser.fulfilled.type) {
    //     // Authentication succeeded, navigate to the admin route
    //     console.log("redirecting")
    //     navigate("/admin");
    //   } else if (resultAction.type === loginUser.rejected.type) {
    //     // Authentication failed, display an error message
    //     console.log("error in login")
    //     setMyError("Authentication failed.");
    //   }
    //   setMyError("dispatch called.");
    // });
    // =========================
    // Dispatch the login action
    // await dispatch(loginUser(myLoginData))
    //  .then((resultAction) => {
    //   console.log(resultAction,"ress")
    //    if (loginUser.fulfilled.match(resultAction)) {
    //     console.log(loginUser.fulfilled)
    //      // Authentication succeeded, navigate to the admin route
    //      navigate("/admin");
    //    } else if (loginUser.rejected.match(resultAction)) {
    //      // Authentication failed, display an error message
    //      setMyError("Authentication failed. Please check your credentials.");
    //    }
    //  })
    //  .catch((error) => {
    //    setMyError("An error occurred during login.");
    //    console.error(error);
    //  });

    // ========================================
    try {
      const resultAction = await dispatch(loginUser(myLoginData));
  
      if (loginUser.fulfilled.match(resultAction)) {
        // Authentication succeeded, navigate to the admin route
        navigate("/admin");
      } else if (loginUser.rejected.match(resultAction)) {
        // Authentication failed, display an error message
        setMyError("Authentication failed. Please check your credentials.");
      }
    } catch (error) {
      // An error occurred during login, display an error message
      setMyError("An error occurred during login.");
      console.error(error);
    }
  
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="md:w-1/2 w-3/4 mx-auto py-32">
        <div className="  shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] shadow-gray-500 rounded-lg p-6">
          <div className="w-full relative flex flex-col justify-center items-center">
            <div className=" brainLogo  absolute -top-16 w-24 h-24 rounded-full flex justify-center items-center">
              <img
                src={brainLogo}
                width={60}
                height={60}
                className="rounded-full mx-auto"
                alt="logo"
              />
            </div>
            <h1 className="text-lg text-center my-2 font-semibold">
              Admin Login
            </h1>
          </div>
          {/* form start */}
          <div className=" form flex flex-wrap justify-center items-center  ">
            <p className="text-red-500 my-1">{!MyError ? "" : MyError}</p>
            <p className="text-red-500 my-1">{!Message ? "" : Message}</p>
            <div className="p-2  w-full">
              <div className="relative">
                <input
                  type="username"
                  id="username"
                  placeholder="Enter Your username"
                  name="username"
                  onChange={getLoginDetails}
                  className=" w-full border-b-2 bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                />
              </div>
            </div>
            <div className="p-2  w-full">
              <div className="relative flex justify-center items-center">
                <div className="w-full">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter Your Password"
                    onChange={getLoginDetails}
                    className="w-full border-b-2 bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                  />
                </div>
                <div className="w-1/6 absolute -right-4 flex justify-center items-center">
                  <span
                    onClick={togglePasswordVisibility}
                    className="absolute  text-2xl  cursor-pointer text-green-500"
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible />
                    ) : (
                      <AiOutlineEye />
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="p-2 w-full">
              <button
                onClick={handleLogin}
                className="flex mx-auto text-green-500  font-bold hover:bg-green-500 border-2 border-green-500 py-2 px-8 focus:outline-none hover:text-white rounded text-lg"
              >
                {loading ? "Loading ..." : "Login"}
              </button>
            </div>
          </div>
          {/* form end  */}
        </div>
      </div>
      {/* <Toaster /> */}
    </>
  );
};

export default Login;
