import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const token = localStorage.getItem("accessToken");

export const createEmployee = createAsyncThunk(
  "createEmployee",
  async (data, { rejectWithValue }) => {
    const response = await fetch("http://193.203.162.247:9090/monster/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    // console.log(result, "resss");
    return result;
  }
);
//========================= read team ==================

export const showEmployee = createAsyncThunk(
  "getTeam",
  async (args, { rejectWithValue }) => {
    const response = await fetch("http://193.203.162.247:9090/monster");

    try {
      const result = await response.json();
      // console.log(result, "reading employees");
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//========================= delete team ==================

export const deleteTeam = createAsyncThunk(
  "deleteTeam",
  async (id, { rejectWithValue }) => {
    const response = await fetch(
      `http://193.203.162.247:9090/monster/delete/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    try {
      const result = await response.json();
      // console.log(result);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// =============== Update Team ===================

export const updateTeam = createAsyncThunk(
  "updateTeam",
  async (data, { rejectWithValue }) => {
    console.log("updated data", data);
    const response = await fetch(
      `http://193.203.162.247:9090/monster/update/${data.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );

    try {
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const teamDetails = createSlice({
  name: "teamDetails",
  initialState: {
    employees: [],
    loading: false,
    error: null,
    empData: [], // Property for showEmployee
  },
  reducers: {}, // You can add additional reducers here
  extraReducers: (builder) => {
    builder
      .addCase(createEmployee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.employees.push(action.payload);
      })
      .addCase(createEmployee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(showEmployee.pending, (state) => {
        state.loading = true;
      })
      .addCase(showEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.empData = action.payload;
      })
      .addCase(showEmployee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateTeam.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTeam.fulfilled, (state, action) => {
        state.loading = false;
        
        const updatedUser = action.payload;
        const index = state.empData.findIndex(
          (user) => user.id === updatedUser.id
        );
        if (index !== -1) {
          state.empData[index] = updatedUser;
        }
      })
      .addCase(updateTeam.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteTeam.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.empData = state.empData.filter(
          (emp) => emp.id !== action.payload
        );
      })
      .addCase(deleteTeam.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default teamDetails.reducer;
