import React from "react";
// import { useScroll, useTransform } from "framer-motion";
// import { motion } from "framer-motion";
import Brain from "../img/brainLogo.png";
import "../App.css";
import  MyTechStack  from "../components/MyTechStack";

const About = () => {

  return (
    <div className={`pt-24 overflow-hidden`} id="about">
      <div className="title container mx-auto py-16 md:text-left text-center">
        <div
          // id="myCursor"
          className="absolute"
        ></div>
        <h1
          data-aos="fade-up"
          data-aos-delay="400"
          className={` md:text-6xl text-4xl md:w-1/2 w-full  font-bold`}
        >
          We are{" "}
        </h1>
        <h1 className="font-bold md:text-8xl text-6xl">
          {" "}
          <span data-aos="fade-up" data-aos-delay="500" className=" text-green-500">
            MONSTER
          </span>{" "}
          <span data-aos="fade-up" data-aos-delay="600" className={``}>
            BRAINS
          </span>{" "}
        </h1>
      </div>
      <div className="objective container mx-auto px-8 flex justify-between items-center md:flex-row flex-col">
        <div className="md:w-1/2 w-full mb-4 flex justify-center">
          <div data-aos="fade-up" data-aos-delay="500" className="logoSection">
            <img src={Brain} width={300} height={300} alt="Monsterbrains Logo" className="hover:scale-110 transition duration-500 cursor-pointer object-cover" />
          </div>
        </div>
        <div data-aos="fade-up" data-aos-delay="500" className="md:w-1/2 w-full mb-4">
          <h5 className="font-bold text-green-500">WHO WE ARE</h5>
          <p className="text-2xl capitalize">
            MONSTERBRAINS is a well-diversified development-focused tech agency
          </p>
          <p className="text-lg text-zinc-600 capitalize">
            We believe that technology can empower people to realize their ideas and bring their imagination to life where innovation transforms into tangible solutions and dreams converge with reality. <br />
             Our commitment to delivering affordable, reliable, and efficient IT services sets us apart in the technology landscape. Explore how our expertise can elevate your business in the digital world.
            we have been trailblazers in the IT service sector, driven by our mission to bring cutting-edge technology solutions to businesses of all types. Our vision is rooted in making technology accessible and efficient for everyone.
          </p>
        </div>
      </div>
      <div data-aos="fade-up" data-aos-delay="500" className="objective container mx-auto py-8 px-8 flex justify-between items-center md:flex-row-reverse flex-col">
        <div className="md:w-1/2 w-full mb-4 flex justify-center">
          <div className="logoSection">
            <img src={Brain} width={300} height={300} alt="Monsterbrains Logo" className="hover:scale-110 transition duration-500 cursor-pointer object-cover"/>
          </div>
        </div>
        <div className="md:w-1/2 w-full mb-4">
          <h5 className="font-bold text-green-500">WHAT WE DO</h5>
          <p className="text-lg text-zinc-600 capitalize">
          We offer a comprehensive range of IT services, from development to system integration and beyond. Each service is designed to streamline your operations and enhance your technological capabilities.
          </p>
        </div>
      </div>
      <MyTechStack/>
    </div>
  );
};

export default About;
