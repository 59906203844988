import React, { useEffect, useState } from "react";
import SingleProject from "./SingleProject";
import ProductModal from "./ProductModal";
import mkite from '../img/works/mkite.png'
import edgocart from '../img/works/edgocart.png'
import leafers from '../img/works/leafers.jpg'
// import { ContextData } from "../Context/Context";
import gsap from "gsap";

const projects = [
  {
    id:111,
    title: "Mkite",
    src: mkite,
    color: "#000000",
    category:"medical",
    projectUrl:"http://mkite.in"
  },
  {
    id:112,
    title: "Edgocart",
    src: edgocart,
    color: "#020617",
    category:"transport",
    projectUrl:"http://edgocart.monsterbrains.in"

  },
  {id:113,
    title: "Leafers",
    src: leafers,
    color: "#EFE8D3",
    category:"ecommerce",
    projectUrl:"http://leafers.monsterbrains.in/"

  },
];

const ProjectList = () => {
  const [modal, setModal] = useState({ active: false, index: 0 });
  // const {bgColor,textColor} = ContextData()
useEffect(()=>{
 window.addEventListener("mousemove",(dets)=>{
    gsap.to("#myCursor", {
      left: dets.x,
      top: dets.y,
    });
},[])
 
  })

  return (
    <div className={`  py-12`}>
      <div id=" fixed -z-50 w-32 h-32 text-yellow-300 bg-pink-300 rounded-full"></div>
      <div className="container mx-auto py-8 px-8 relative overflow-hidden -mt-12">
        <div data-aos="fade-up" data-aos-delay="400"  className="mycircle md:w-1/3 w-32 md:h-2/4 h-32 absolute opacity-60  md:-top-28 -top-2/3 right-1 bg-zinc-900 rounded-full"></div>
        <h1 data-aos="fade-up" data-aos-delay="500" className="text-2xl font-bold text-green-500">PROJECTS</h1>
        <h1 data-aos="fade-up" data-aos-delay="600" className="w-3/4 md:text-8xl text-6xl capitalize ProjectPageHeading  About_title">
          {" "}
          Crafted With Dedication And Love{" "}
        </h1>
        <div data-aos="fade-up" data-aos-delay="500" className=" container mx-auto my-2 flex justify-end  ">
          <div className=" md:w-1/3 w-full">
            <p className="text-lg ">
              we love what we do and combine our expert knowledge with
              enthusiasm to go above and beyond for our clients. Check out some
              of our best work.
            </p>
          </div>
        </div>

        <ul className="text-5xl my-6">
          {projects.map((ele, index) => {
            return (
              <div key={ele.id}>
                {/* <li className="my-4 font-semibold stroke-2">{ele.title}</li> */}
                <SingleProject
                  index={index}
                  title={ele.title}
                  setModal={setModal}
                  category={ele.category}
                  projectUrl={ele.projectUrl}
                  
                  
                />
              </div>
            );
          })}

          <li data-aos="fade-up" data-aos-delay="600" className="my-4 italic  stroke-2 text-zinc-600">
            Your Project Here
          </li>
        </ul>
        <ProductModal modal={modal} projects={projects}/>
      </div>
    </div>
  );
};

export default ProjectList;
