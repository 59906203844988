export const missingCountries = [
    { name: "51/10h/4g shree Balaji Bhawan West Arjun Nagar Agra ,282001", latLng: [27.1767, 78.0081] },

  ];
  
  export const countries = {
    IN: 88,
    BD: 1,
    BT: 5,
    NP: 19,
    PK: 20,
    MM: 40,
    LK: 13,
    AF:25,
  };
  
  export const colorScale = ["#F28C28"];
  