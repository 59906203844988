import { configureStore } from "@reduxjs/toolkit";
import teamDetails from "../utils/teamSlice";
import testimonialDetails from "../utils/testimonialSlice";
import authDetails from '../utils/authSlice'
import BlogDetails from '../utils/blogSlice'
import uploadDetails from '../utils/uploadSlice'

export const store = configureStore({
   reducer:{
    team:teamDetails,
    testimonial:testimonialDetails,
    auth:authDetails,
    blog:BlogDetails,
    upload:uploadDetails,
   }
})