import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Admin from "./components/admin/Admin";
import Employee from "./components/admin/Employee";
import AddEmployee from "./components/admin/AddEmployee";
import ShowEmployee from "./components/admin/ShowEmployee";
import AddTestimonial from "./components/admin/AddTestimonial";
import ShowTestimonial from "./components/admin/ShowTestimonial";
import Error from "./components/Error";
import Login from "./components/admin/Login";
import Register from "./components/admin/Register";
import Blogs from "./pages/Blogs";
import AddBlog from "./components/admin/AddBlog";
import AllBlogs from "./components/admin/AllBlogs";
import SingleBlog from "./components/SingleBlog";
import ConditionalNavbar from "./components/ConditionalNavbar";
import AddEmployeeForm from "./components/admin/AddEmployeeForm";

function App() {
  const [bgColor, setBgColor] = useState("#ffffff");
  const [textColor, setTextColor] = useState("");
  

  useEffect(() => {
    const currentTime = new Date().getHours();

    if (currentTime >= 6 && currentTime < 18) {
      setBgColor("bg-white");
      setTextColor("text-black");
    } else {
      setBgColor("bg-black");
      setTextColor("text-white");
    }
  }, []);

  // Check if the user is authenticated based on local storage
  const isAuthenticated = localStorage.getItem("accessToken") !== null;

  return (
    <div className={`${bgColor} ${textColor} App`}>
      <Router>
        <ConditionalNavbar />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/employee/add/:id" element={<AddEmployee/>} />
          {/* <Route path="/register" element={<Register />} /> */}

          {/*===== Protected admin routes ==== */}
          <Route path="/admin" element={isAuthenticated ? <Admin /> : <Navigate to="/login" />}>
          {/* <Route path="/admin" element={isAuthenticated ? <Admin /> : navigate("/admin", { replace: true })}> */}
            <Route path="/admin/employees" element={<ShowEmployee />} />
            <Route path="/admin/employees/add" element={isAuthenticated ? <AddEmployeeForm /> : <Navigate to="/login" />} />
            {/* <Route path="/admin/employee/add/:name" element={isAuthenticated ? <AddEmployee /> : <Navigate to="/login" />} /> */}
            <Route path="/admin/testimonials" element={<ShowTestimonial />} />
            <Route path="/admin/register" element={<Register />} />
            <Route path="/admin/blogs" element={isAuthenticated ? <AllBlogs /> : <Navigate to="/login" />} />
          <Route path="/admin/blogs/add" element={isAuthenticated ? <AddBlog /> : <Navigate to="/login" />} />
            <Route path="/admin/testimonials/add" element={isAuthenticated ? <AddTestimonial /> : <Navigate to="/login" />} />
            <Route path="*" element={<Error />} />
          </Route>
          {/* End of protected admin routes */}
          
          <Route path="/contact" element={<Contact />} />
          <Route path="/blogs" element={ <Blogs /> } />
          <Route path="/:name" exact element={<Employee />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/employee/add" element={ <AddEmployee /> } /> */}
          <Route path="/blogs/:id" element={<SingleBlog />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
