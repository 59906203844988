import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { showEmployee } from "../utils/teamSlice";
import "./Teams.css";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import AOS from "aos";
// import img1 from "../img/teams/myimg.png";
import { getFile } from "../utils/uploadSlice";

const Teams = () => {
  AOS.init();

  const dispatch = useDispatch();
  const { empData } = useSelector((state) => state.team);

  useEffect(() => {
    dispatch(showEmployee());
  }, [dispatch]);

  useEffect(() => {
    // Fetch images for each employee using dispatch(getFile())
    if (empData) {
      empData.forEach((emp) => {
        dispatch(getFile({ id: emp.imgUrl }));
      });
    }
  }, [dispatch, empData]);

  console.log(empData)
  return (
    <div className="bg-slate-950 py-8">
      <h1
        data-aos="fade-up"
        data-aos-delay="400"
        className="text-center my-8 px-4 text-white md:text-8xl text-5xl About_title"
      >
        Meet With Our Team
      </h1>
      <div
        data-aos="fade-up"
        data-aos-delay="500"
        className="container mx-auto px-4 py-4"
      >
        <Swiper
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={3}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1,
          }}
          pagination={{
            el: ".swiper-pagination",
            clickable: true,
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container"
        >
          {empData &&
            empData.map((emp) => (
              <SwiperSlide key={emp.id} className="">
                <Link to={`/${emp.firstName}`} target="_blank" key={emp.id}>
                  <img
                    src={`http://193.203.162.247:9090/download/${emp.imgUrl}`} 
                    alt="slide_image"
                    className="rounded-xl smd:w-80 md:w-96 w-64 smd:h-80 md:h-80 h-64"
                  />
                  <p className="text-center md:text-xl text-lg text-green-500 capitalize">
                    {emp.firstName}
                  </p>
                  <p className="text-center md:text-xl  text-white capitalize -my-1">
                    {emp.designationTitle}
                  </p>
                </Link>
              </SwiperSlide>
            ))}

          <div className="swiper-pagination"></div>
        </Swiper>
      </div>
    </div>
  );
};

export default Teams;
