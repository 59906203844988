import React from "react";
import Logo from "../img/Logo.png";
import { Link } from "react-router-dom";
import { AiFillInstagram } from "react-icons/ai";
import { BsFacebook, BsLinkedin } from "react-icons/bs";

import "../App.css";

const Footer = () => {
  return (
    <>
      <footer className="footer text-white body-font bg-black border-green-500  border-t-2 pb-4 pt-8">
        <div className="container px-5 py-2 mx-auto flex md:justify-between justify-center items-center md:flex-row flex-col">
          <div className="md:w-1/3 w-full flex md:justify-start justify-center items-center">
            <div>
              <Link
                href={"/"}
                className=" title-font font-medium text-gray-900"
              >
                <img src={Logo} width={180} height={80} alt="logo" />
                <span className="ml-3 text-xl"></span>
              </Link>
            </div>
          </div>
          <div>
            <p className="text-sm text-gray-50 text-center   sm:border-gray-200 mb-2 ">
              © {new Date().getFullYear()} Monsterbrains All Rights Reserved
            </p>
          </div>
          <div className="md:w-1/3 w-full flex md:justify-end justify-center ">
            <Link to={"https://www.linkedin.com/company/monsterbrains/"}>
              <h1 className="hover:text-green-500 text-2xl font-semibold mx-2">
                <BsLinkedin />
              </h1>
            </Link>
            <Link to={"https://facebook.com/monsterbrains "}>
              {""}
              <h1 className="hover:text-green-500 text-2xl font-semibold mx-2">
                <BsFacebook />
              </h1>
            </Link>
            <Link
              to={
                "https://instagram.com/monster__brains?utm_source=qr&igshid=ZTM4ZDRiNzUwMw=="
              }
            >
              <h1 className="hover:text-green-500 text-3xl font-semibold mx-2">
                <AiFillInstagram />
              </h1>
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
