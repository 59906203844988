import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import brainLogo from '../../img/brainLogo.png'
import {MdOutlineContentCopy} from 'react-icons/md'
import { v4 as uuidv4 } from 'uuid';

const AddEmployeeForm = () => {
    const [email, setEmail] = useState("");
    const [formLink, setFormLink] = useState("");
    const myid = uuidv4();
    const local_url = process.env.REACT_APP_Local_URL;

    console.log(local_url,"local")
    console.log(process.env,"env")

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(formLink);
      toast.success("Copied to clipboard");
    } catch (error) {
      toast.error("Copy failed");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setFormLink(`${local_url}/employee/add/${myid}${email}`);
    // setFormLink(`http://localhost:3000/employee/add/${myid}`);
    setFormLink(`http://monsterbrains.in/employee/add/${email}${myid}`);
    toast.success("Form Created");
  };

  return (
    <>
      <div className="md:w-3/4  md:mx-auto mx-2 my-10">
        <div className="  shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] shadow-gray-300 rounded-lg p-6">
          <div className="w-full relative flex flex-col justify-center items-center">
            <div className=" brainLogo  absolute -top-16 w-24 h-24 rounded-full flex justify-center items-center">
              <img
                src={brainLogo}
                width={60}
                height={60}
                className="rounded-full mx-auto"
                alt="logo"
              />
            </div>
            <h1 className="text-lg text-center my-2 font-semibold">
              Add Employee
            </h1>
          </div>
          {/* form start */}
          <div className=" form flex flex-wrap justify-center items-center  ">
            <div className="p-2  w-full">
              <div className="relative ">
                <input
                  type="email"
                  id="email"
                  placeholder="email"
                  name="company"
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                />
              </div>
            </div>
            <div className="p-2  w-full">
              <div className="relative flex justify-center items-center">
                <div className="input w-full">
                  <input
                    type="text"
                    id="addLink"
                    placeholder="url for employee"
                    value={formLink}
                    name="addLink"
                    className="w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                  />
                </div>
                <div className="icon w-8 h-9  absolute right-1 rounded-md bg-gray-600 text-green-500 overflow-hidden">
                  <span onClick={handleCopy} className="text-2xl py-2 hover:cursor-pointer flex justify-center items-center">
                    <MdOutlineContentCopy/>
                  </span>
                </div>
              </div>
            </div>
            <div className="p-2 w-full">
              <button
                onClick={handleSubmit}
                className="flex mx-auto text-green-500  font-bold hover:bg-green-500 border-2 border-green-500 py-2 px-8 focus:outline-none hover:text-white rounded text-lg"
              >
                Create Url
              </button>
            </div>
          </div>
          {/* form end  */}
        </div>
      </div>
      <Toaster />
    </>
  );
}

export default AddEmployeeForm;
