import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Blogs from "../pages/Blogs";

const SingleBlog = () => {
  const { blogData } = useSelector((state) => state.blog);
  const { id } = useParams();
  const [filteredBlog, setFilteredBlog] = useState([]);

  console.log("URL ID:", id);

  useEffect(() => {
    const fetcheditem = blogData.find((curEle) => curEle.id === 13);
    console.log(fetcheditem, "jhjhj");
    if (fetcheditem) {
      setFilteredBlog(fetcheditem);
    }

    console.log("Updated Blog Data:", blogData);
    console.log("Filtered Blog:", filteredBlog);
  }, [filteredBlog,blogData, id]);

  return (
    <div className="pt-24 container mx-auto min-h-screen">
      {filteredBlog ? (
        <>
        <div className=" py-8 px-4 container mx-auto blogContent border">
          <h1 className="text-4xl capitalize mb-2 text-center font-bold">
            {" "}
            {filteredBlog.title}
          </h1>
          <p>
            {" "}
            <span className="bg-green-500 rounded-xl px-2  ">Author</span>{" "}
            {filteredBlog.author}
          </p>
          <p>
            {" "}
            <span className="bg-green-500 rounded-xl px-2  ">Genre</span>{" "}
            {filteredBlog.genre}
          </p>

          <p>
            {" "}
            <span className="bg-green-500 rounded-xl px-2  ">
              Technology
            </span>{" "}
            {filteredBlog.technology}
          </p>
          <p className=""> {filteredBlog.description}</p>
          <p>
            {" "}
            <span className="bg-green-500 rounded-xl px-2  ">
              Refrence
            </span>{" "}
            {filteredBlog.url}
          </p>

         
          
        </div>
        <div className="otherBlogs ">
            <Blogs/>
          </div>
        </>
        
      ) : (
        <div>No blog found with the specified ID.</div>
      )}
    </div>
  );
};

export default SingleBlog;
