import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import './Testimonials.css';
import { FreeMode, Pagination,Autoplay } from 'swiper/modules';
import { useDispatch, useSelector } from "react-redux";
import {
  showTestimonials,
} from "../utils/testimonialSlice";

const Testimonials = () => {
  const dispatch = useDispatch();
  const { testimonialsData } = useSelector((state) => state.testimonial);

  useEffect(() => {
    dispatch(showTestimonials());
  }, [dispatch]);
  return (
    <div id="testimonials" className={` container-fluid overflow-hidden mx-auto py-32 border-b-2 border-slate-700 `}>
      <div className="section h-96 flex md:flex-row flex-col justify-center items-center">
        <div className="md:w-1/4 w-full md:text-6xl text-4xl font-bold About_title md:pl-8 md:px-0 px-4 text-center">
          <h1 data-aos="fade-up" data-aos-delay="400">
            What Our Client <br /> Says ?
          </h1>
        </div>
        <div data-aos="fade-up" data-aos-delay="500" className="md:w-2/3 w-full px-4 my-4 text-black ">
        <Swiper
        slidesPerView={3}
        spaceBetween={30}
        loop={true}
        rewind={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        freeMode={true}
        breakpoints={{
          // when window width is >= 640px
          280: {
            width: 280,
            slidesPerView: 1,
          },
          640: {
            width: 640,
            slidesPerView: 2,
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 3,
          },
        }}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination,Autoplay]}
        className="mySwiper"
      >
        {testimonialsData && testimonialsData.map((item)=>(
          <SwiperSlide key={item.id} className=" shadow-2xl  mySwiperSlide text-center flex justify-center items-center flex-col">
              <p className="text-lg text-semibold">
                {item.testimonials}
              </p>
              <h1 className="my-4 titles text-green-500">{item.company}</h1>
            </SwiperSlide>
        ))}
            
          
           
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
