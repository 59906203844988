import { useEffect, useState } from "react";
import AboutEmployee from "../admin/AboutEmployee";
import Contact from "../../pages/Contact";
import HeroEmployee from "../HeroEmployee";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Employee = () => {
  const { empData } = useSelector((state) => state.team);
  const [updatedItem, setUpdatedItem] = useState([]);
  const { name } = useParams();
  // console.log(name, "name Item");

  useEffect(() => {
    const fetcheditem = empData.find((curEle) => curEle.firstName === name);

    if (fetcheditem) {
      setUpdatedItem(fetcheditem);
    }
    // console.log(updatedItem, "updated Item");
  }, [name, empData, updatedItem]);

  return (
    <div className="">
      {!updatedItem ? (
        <h1 className="pt-20">404 Not Found</h1>
      ) : (
        <>
          <HeroEmployee updatedItem={updatedItem} />
          <AboutEmployee updatedItem={updatedItem} />
          <Contact updatedItem={updatedItem} />
        </>
      )}
    </div>
  );
};

export default Employee;
