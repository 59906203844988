import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const token = localStorage.getItem("accessToken");
export const createTestimonial = createAsyncThunk(
  "createTestimonial",
  async (data, { rejectWithValue }) => {
    const response = await fetch(
      "http://193.203.162.247:9090/testimonials/create",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    // console.log(result, "resss");
    return result;
  }
);

export const showTestimonials = createAsyncThunk(
  "getTestimonials",
  async (args, { rejectWithValue }) => {
    const response = await fetch("http://193.203.162.247:9090/testimonials");

    try {
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// =============== Update Team ===================

export const updateTestimonial = createAsyncThunk(
  "updateTestimonial",
  async (data, { rejectWithValue }) => {
    console.log("updated data", data);
    const response = await fetch(
      `http://193.203.162.247:9090/testimonials/update/${data.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );

    try {
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//========================= delete testimonial ==================

export const deleteTestimonials = createAsyncThunk(
  "deleteTeam",
  async (id, { rejectWithValue }) => {
    const response = await fetch(
      `http://193.203.162.247:9090/testimonials/delete/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    try {
      const result = await response.json();
      console.log(result);
      // console.log("delete done")
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const testimonialDetails = createSlice({
  name: "testimonialDetails",
  initialState: {
    testimonials: [],
    loading: false,
    error: null,
    testimonialsData: [], // Property for showTestimonials
  },
  reducers: {}, // You can add additional reducers here
  extraReducers: (builder) => {
    builder
      .addCase(createTestimonial.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTestimonial.fulfilled, (state, action) => {
        state.loading = false;
        state.testimonials.push(action.payload);
      })
      .addCase(createTestimonial.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(showTestimonials.pending, (state) => {
        state.loading = true;
      })
      .addCase(showTestimonials.fulfilled, (state, action) => {
        state.loading = false;
        state.testimonialsData = action.payload;
      })
      .addCase(showTestimonials.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateTestimonial.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTestimonial.fulfilled, (state, action) => {
        state.loading = false;
        // const taskIndex = state.tasks.findIndex((task) => task.id === action.payload.id);
        // if (taskIndex !== -1) {
        //   state.tasks[taskIndex] = action.payload;
        // }
        //-------------------
        //   state.employees = state.employees.map((ele) =>
        //   ele.id === action.payload.id ? action.payload : ele
        // );
        // -------------------
        const updatedUser = action.payload;
        const index = state.testimonialsData.findIndex(
          (item) => item.id === updatedUser.id
        );
        if (index !== -1) {
          state.empData[index] = updatedUser;
        }
      })
      .addCase(updateTestimonial.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteTestimonials.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTestimonials.fulfilled, (state, action) => {
        state.loading = false;
        state.testimonialsData = state.testimonialsData.filter(
          (testimonial) => testimonial.id !== action.payload
        );
      })
      .addCase(deleteTestimonials.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default testimonialDetails.reducer;
