import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const token = localStorage.getItem("accessToken");

export const createBlog = createAsyncThunk(
  "createBlog",
  async (data, { rejectWithValue }) => {
    const response = await fetch("http://193.203.162.247:9090/blogs/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    // console.log(result, "resss");
    return result;
  }
);
//========================= read all team ==================

export const showBlog = createAsyncThunk(
  "showBlog",
  async (args, { rejectWithValue }) => {
    const response = await fetch("http://193.203.162.247:9090/blogs/get");
    try {
      const result = await response.json();
      // console.log(result, "reading allBlogs");
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//========================= delete Blog ==================

export const deleteBlog = createAsyncThunk(
  "deleteBlog",
  async (id, { rejectWithValue }) => {
    const response = await fetch(
      `http://193.203.162.247:9090/blogs/delete/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    try {
      const result = await response.json();
      console.log(result);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// =============== Update Blog ===================

export const updateBlog = createAsyncThunk(
  "updateBlog",
  async (data, { rejectWithValue }) => {
    console.log("updated data", data);
    const response = await fetch(
      `http://193.203.162.247:9090/blogs/update/${data.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );

    try {
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const BlogDetails = createSlice({
  name: "BlogDetails",
  initialState: {
    allBlogs: [],
    loading: false,
    error: null,
    blogData: [], // Property for showBlog
  },
  reducers: {}, // You can add additional reducers here
  extraReducers: (builder) => {
    builder
      .addCase(createBlog.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.allBlogs.push(action.payload);
      })
      .addCase(createBlog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(showBlog.pending, (state) => {
        state.loading = true;
      })
      .addCase(showBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.blogData = action.payload;
      })
      .addCase(showBlog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateBlog.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBlog.fulfilled, (state, action) => {
        state.loading = false;

        const updatedBlog = action.payload;
        const index = state.blogData.findIndex(
          (item) => item.id === updatedBlog.id
        );
        if (index !== -1) {
          state.blogData[index] = updatedBlog;
        }
      })
      .addCase(updateBlog.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteBlog.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBlog.fulfilled, (state, action) => {
        state.loading = false;
        state.blogData = state.blogData.filter(
          (item) => item.id !== action.payload
        );
      })
      .addCase(deleteBlog.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default BlogDetails.reducer;
