import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


//========================== register ===============
export const registerUser = createAsyncThunk(
    "registerUser",
    async (data, { rejectWithValue }) => {
      const response = await fetch("http://193.203.162.247:9090/api/auth/admin/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        const result = await response.json();
        // console.log(result, "resss");
        return result;
      } else {
        const error = await response.json();
        throw new Error(error.message);
      }
    }
  );
//================== login ===================
export const loginUser = createAsyncThunk(
    "loginUser",
    async (credentials, { rejectWithValue }) => {
      const response = await fetch("http://193.203.162.247:9090/api/auth/signin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });
  
      try {
        if (response.ok) {
          const result = await response.json();
          const accessToken = result.accessToken;
          // Store the accessToken in local storage
          let myuser = result.username
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("User", myuser);
          return result;
        } else {
          throw new Error("Login failed");
        }
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  
  

//========================= logout ==================

export const logoutUser = createAsyncThunk(
  "logoutUser",
  async (_, { rejectWithValue, getState }) => {
    const state = getState();
    const accessToken = state.authDetails.accessToken;

    if (!accessToken) {
      // No access token found, return success
      localStorage.removeItem("accessToken"); // Clear accessToken from local storage
      return "Logout successful";
    }

    const response = await fetch(`http://193.203.162.247:9090/api/auth/logout`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    try {
      if (response.ok) {
        localStorage.removeItem("accessToken"); // Clear accessToken from local storage
        return "Logout successful";
      } else {
        throw new Error("Logout failed");
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


// Create an authSlice for authentication actions
const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loading: false,
    error: null,
    accessToken: localStorage.getItem("accessToken") || "",
    message: "",
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        // Update state as needed on successful registration
        state.user = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        // Handle registration error and set an error message
        state.error = action.error.message;
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = "";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        // Update state as needed on successful login
        state.user = action.payload;
        state.accessToken = action.payload.accessToken;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        // Handle login error and set an error message
        state.error = action.payload;
        state.message = "Invalid User Credentials!!";
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.loading = false;
        // Update state as needed on successful logout
        state.user = null;
        state.accessToken = "";
        state.message = "";
      })
      .addCase(logoutUser.rejected, (state) => {
        state.loading = false;
        // Handle logout error, if needed
      });
  },
});

export const { clearError } = authSlice.actions;

export default authSlice.reducer;

