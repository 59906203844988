import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createEmployee } from "../../utils/teamSlice";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import brainLogo from "../../img/brainLogo.png";
import { FcAddImage } from "react-icons/fc";
import { uploadFile } from "../../utils/uploadSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const AddEmployee = () => {
  const [employeeData, setEmployeeData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  // const [myProfileId, setmyProfileId] = useState("");
  const [myProfileId, setMyProfileId] = useState("");
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState("");
  const navigate = useNavigate();
  const paramUrl = useParams();
  const location = useLocation();

  const urlPathName = location.pathname;
  console.log(urlPathName, "url");
  useEffect(() => {
    const myParamUrl = localStorage.getItem("paramUrl");

    if (urlPathName === myParamUrl) {
      navigate("/");
    }
  }, [urlPathName]);

  const getEmployeeData = (e) => {
    const { name, value } = e.target;
    setEmployeeData({ ...employeeData, [name]: value });
  };

  const handleImageChange = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const reader = new FileReader();

    reader.onload = (e) => {
      setImagePreview(e.target.result);
    };

    reader.readAsDataURL(e.target.files[0]);
    setProfileImage(formData);

    dispatch(uploadFile(formData))
      .then((result) => {
        const profileId = result.payload.id;
        setMyProfileId(profileId);

        setEmployeeData({
          ...employeeData,
          imgUrl: profileId,
        });

        toast.success("Image uploaded successfully!");
      })
      .catch((error) => {
        console.error("Upload failed: ", error);
        toast.error("Image upload failed.");
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await dispatch(createEmployee(employeeData));
    toast.success("Employee Added");

    // Mark the form as submitted
    localStorage.setItem("paramUrl", urlPathName);

    setIsSubmitted(true);
    navigate("/");
  };

  return (
    <>
      <div className="md:w-3/4 pt-20  md:mx-auto mx-2 my-10">
        <div className="  shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] shadow-gray-300 rounded-lg p-6">
          <div className="w-full relative flex flex-col justify-center items-center">
            <div className=" brainLogo  absolute -top-16 w-24 h-24 rounded-full flex justify-center items-center">
              <img
                src={brainLogo}
                width={60}
                height={60}
                className="rounded-full mx-auto"
                alt="logo"
              />
            </div>
            <h1 className="text-lg text-center my-2 font-semibold">
              Add An Employee
            </h1>
          </div>
          {/* form start */}
          <div className="flex  flex-col justify-center items-center ">
            <div
              // onClick={handleImageClick}
              className="md:w-1/4 w-full flex flex-col justify-center items-center"
            >
              <label className="text-blue border-green-500 hover:bg-blue flex md:h-40 h-24 md:w-40 w-24 cursor-pointer flex-col items-center justify-center rounded-full border bg-white uppercase tracking-wide shadow-lg hover:text-green-500">
                {!profileImage ? (
                  <FcAddImage className="md:text-8xl text-6xl" />
                ) : (
                  <div className="flex justify-center items-center">
                    <img
                      src={imagePreview}
                      alt="Profile"
                      className="rounded-full"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                )}
                <span className="mt-2  text-sm leading-normal">
                  {/* Upload Profile */}
                </span>
                <input
                  type="file"
                  name="profile"
                  id="profile"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="hidden"
                  // ref={inputref}
                  style={{ display: "none" }}
                />
              </label>
              {/* <button className="bg-green-500 text-white px-4 py-2" onClick={handleImage}>Upload</button> */}
            </div>
            <div className="md:w-3/4 w-full ">
              <div className=" form flex flex-wrap justify-center items-center  ">
                <div className="p-2 md:w-1/4 w-full">
                  <div className="relative">
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="First Name  "
                      onChange={getEmployeeData}
                      className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                    />
                  </div>
                </div>
                <div className="p-2 md:w-1/4 w-full">
                  <div className="relative">
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name "
                      onChange={getEmployeeData}
                      className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                    />
                  </div>
                </div>
                <div className="p-2 md:w-1/4 w-full">
                  <div className="relative">
                    <input
                      type="text"
                      id="name"
                      name="email"
                      placeholder="email  "
                      onChange={getEmployeeData}
                      className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                    />
                  </div>
                </div>
                <div className="p-2 md:w-1/4 w-full">
                  <div className="relative">
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder="Contact Number  "
                      onChange={getEmployeeData}
                      className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                    />
                  </div>
                </div>
                <div className="p-2 md:w-1/4 w-full">
                  <div className="relative">
                    <input
                      type="text"
                      id="designationTitle"
                      name="designationTitle"
                      placeholder="Designation"
                      onChange={getEmployeeData}
                      className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                    />
                  </div>
                </div>
                <div className="p-2 md:w-1/4 w-full">
                  <div className="relative">
                    <input
                      type="email"
                      id="tagline"
                      name="tagline"
                      placeholder="tagline : Professional developer with an eye htmlFor detail"
                      onChange={getEmployeeData}
                      className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                    />
                  </div>
                </div>
                <div className="p-2 md:w-1/4 w-full">
                  <div className="relative">
                    <input
                      type="text"
                      id="skills"
                      name="skills"
                      placeholder="skills : Angular,React"
                      onChange={getEmployeeData}
                      className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                    />
                  </div>
                </div>
                <div className="p-2 md:w-1/4 w-full">
                  <div className="relative">
                    <input
                      type="text"
                      id="vision"
                      name="vision"
                      placeholder="Vision: I Enjoying Solving Problems With Clean Scalable Solutions.I Have Genuine Passion Of Aspiring Dvelopment."
                      onChange={getEmployeeData}
                      className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                    />
                  </div>
                </div>
                <div className="p-2 md:w-1/4 w-full">
                  <div className="relative">
                    <input
                      type="text"
                      id="strength"
                      name="strength"
                      placeholder="Strength : problem Solver"
                      onChange={getEmployeeData}
                      className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                    />
                  </div>
                </div>
                <div className="p-2 md:w-1/4 w-full">
                  <div className="relative">
                    <input
                      type="text"
                      id="linkedin"
                      name="linkedin"
                      placeholder="linked in url"
                      onChange={getEmployeeData}
                      className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                    />
                  </div>
                </div>
                <div className="p-2 md:w-1/4 w-full">
                  <div className="relative">
                    <input
                      type="text"
                      id="github"
                      name="github"
                      placeholder="github url"
                      onChange={getEmployeeData}
                      className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                    />
                  </div>
                </div>
                <div className="p-2 md:w-1/4 w-full">
                  <div className="relative">
                    <input
                      type="text"
                      id="experience"
                      placeholder="Experience in (years)"
                      name="experience"
                      onChange={getEmployeeData}
                      className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                    />
                  </div>
                </div>
                <div className="p-2 md:w-1/4 w-full">
                  <div className="relative">
                    <input
                      type="text"
                      id="projects"
                      placeholder="projects ex: 6"
                      name="projects"
                      onChange={getEmployeeData}
                      className=" w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                    />
                  </div>
                </div>
                {/* <input type="text" ref={inputref}  className="text-green-500" onChange={getEmployeeData} name="imgUrl" id="imgUrl" /> */}

                <div className="p-2 w-full">
                  <button
                    onClick={handleSubmit}
                    disabled={isSubmitted} // Disable the button if isSubmitted is true
                    className="flex mx-auto text-green-500 font-bold hover:bg-green-500 border-2 border-green-500 py-2 px-8 focus:outline-none hover:text-white rounded text-lg"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* form end  */}
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default AddEmployee;
