import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showTestimonials,
  deleteTestimonials,
  updateTestimonial,
} from "../../utils/testimonialSlice";
import { Link } from "react-scroll";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

const ShowTestimonial = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { testimonialsData } = useSelector((state) => state.testimonial);
  const [testimonialId, setTestimonialId] = useState("");
  const [singleTestimonial, setSingleTestimonial] = useState({
    company: "",
    testimonials: "",
  });

  useEffect(() => {
    dispatch(showTestimonials());
  }, [dispatch]);

  const getSingleTestimonial = testimonialsData.find(
    (ele) => ele.id === testimonialId
  );

  useEffect(() => {
    setSingleTestimonial(
      getSingleTestimonial || { company: "", testimonials: "" }
    );
  }, [testimonialId, getSingleTestimonial]);

  const handleEdit = (id) => {
    setTestimonialId(id);
    setShowModal(true);
  };

  const handleUpdate = () => {
    dispatch(updateTestimonial(singleTestimonial));
    toast.success("Item updated");
    setShowModal(false);
  };

  return (
    <>
      <div>
        <h1 className="my-4 font-bold text-center text-xl underline"> All Testimonials</h1>
      </div>
      <div className="container flex justify-center flex-wrap mx-auto px-4 items-center">
        {testimonialsData.map((ele) => (
          <div
            className="empbox md:w-1/3 w-full  capitalize hover:bg-green-500 p-4 m-4 rounded-lg shadow-xl border-2 border-gray-600"
            key={ele.id}
          >
            <h1>Client Name: {ele.company} </h1>
            <h1>Message : {ele.testimonials} </h1>

            <div className="flex justify-between items-center text-xl border-t-2 border-gray-600 p-2">
              <Link
                onClick={() => handleEdit(ele.id)}
                className="hover:text-yellow-500 cursor-pointer"
              >
                <AiFillEdit />
              </Link>
              <Link
                onClick={() => {
                  dispatch(deleteTestimonials(ele.id));
                  toast.success("Item deleted");
                }}
                className="hover:text-red-600 cursor-pointer"
              >
                <AiFillDelete />
              </Link>
            </div>
          </div>
        ))}
      </div>

      <>
        {showModal ? (
          <>
            <div className="justify-center items-center mx-auto flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-3/4 my-0 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg mx-auto relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-end pt-3 pb-1 w-full rounded-t">
                    <button
                      className="p-1 ml-auto bg-transparent border-0  text-black mr-2 float-right text-6xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-4xl text-red-600  h-6 w-6  block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="flex flex-wrap -m-2 shadow-lg hover:shadow-lg px-6 cursor-pointer pb-3 ">
                    <div className="p-2 w-full">
                      <div className="relative">
                        <label
                          htmlFor="clientName"
                          className="leading-7 text-sm text-gray-600"
                        >
                          Client Name
                        </label>
                        <input
                          type="text"
                          id="clientName"
                          name="company"
                          placeholder="xyz Pvt Ltd"
                          value={singleTestimonial.company}
                          onChange={(e) =>
                            setSingleTestimonial({
                              ...singleTestimonial,
                              company: e.target.value,
                            })
                          }
                          className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus-border-indigo-500 focus-bg-white focus-ring-2 focus-ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                      </div>
                    </div>
                    <div className="p-2 w-full">
                      <div className="relative">
                        <label
                          htmlFor="email"
                          className="leading-7 text-sm text-gray-600"
                        >
                          Feedback
                        </label>
                        <textarea
                          type="text"
                          id="feedback"
                          name="testimonials"
                          placeholder="Your Feedback Here"
                          value={singleTestimonial.testimonials}
                          onChange={(e) =>
                            setSingleTestimonial({
                              ...singleTestimonial,
                              testimonials: e.target.value
                            })
                          }
                          className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus-border-indigo-500 focus-bg-white focus-ring-2 focus-ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                      </div>
                    </div>
                    <div className="p-2 w-full">
                      <button
                        onClick={handleUpdate}
                        className="flex mx-auto text-white bg-green-500 border-0 py-2 px-8 focus-outline-none hover-bg-green-600 rounded text-lg"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
      <Toaster />
    </>
  );
};

export default ShowTestimonial;
