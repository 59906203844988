import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { createBlog } from '../../utils/blogSlice';
import { useNavigate } from 'react-router-dom';


const AddBlog = () => {
  const [myblog, setMyBlog] = useState({});
  const dispatch = useDispatch();
 const navigate=useNavigate()

  const getBlogData = (e) => {
    setMyBlog({ ...myblog, [e.target.name]: e.target.value });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    await dispatch(createBlog(myblog));
    toast.success("blog  Added");
    navigate("/admin/blogs")
  };
 
  return (
    <div className="pt-2 overflow-hidden md:pb-0 mb-10 min-h-screen flex flex-col  items-center" id="blogs">
      <h1 className="text-center  font-semibold text-3xl">Add Blog</h1>
      <div className=" w-3/4 my-4 p-4 shadow-2xl  rounded-lg border container mx-auto border-gray-600">
        {/* form start */}
        <div className="flex flex-wrap justify-center items-center ">
            <div className="p-2 md:w-1/2 w-full">
              <div className="relative">
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Blog Title"
                  onChange={getBlogData}
                  className="w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                />
              </div>
            </div>
            <div className="p-2 md:w-1/2 w-full">
              <div className="relative">
                <input
                  type="text"
                  id="author"
                  name="author"
                  placeholder="Author Name"
                  onChange={getBlogData}
                  className="w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                />
              </div>
            </div>
            
            <div className="p-2 md:w-1/2 w-full">
              <div className="relative">
                <input
                  type="text"
                  id="genre"
                  name="genre"
                  placeholder="Genre of Post"
                  onChange={getBlogData}
                  className="w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                />
              </div>
            </div>
            <div className="p-2 md:w-1/2 w-full">
              <div className="relative">
                <input
                  type="text"
                  id="technology"
                  name="technology"
                  placeholder="Technology"
                  onChange={getBlogData}
                  className="w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                />
              </div>
            </div>
           
                     <div className="p-2 md:w-1/2 w-full">
              <div className="relative">
                <textarea
                  type="text"
                  id="description"
                  name="description"
                  placeholder="Write Blog Here ..."
                  onChange={getBlogData}
                  className="w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0   px-1 mb-2"
                />
              </div>
            </div>
            <div className="p-2 md:w-1/2 w-full">
              <div className="relative">
                <input
                  type="text"
                  id="url"
                  name="url"
                  placeholder="Refrence (if any)"
                  onChange={getBlogData}
                  className="w-full border-b bg-transparent text-green-500 border-gray-600 outline-none outline-0 text-lg py-2 px-1 mb-2"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <button
                onClick={handleSubmit}
                className="flex mx-auto text-green-500 font-bold hover:bg-green-500 border-2 border-green-500 py-2 px-8 focus:outline-none hover:text-white rounded text-lg"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <Toaster/>
        {/* form end */}
      </div>
  );
};

export default AddBlog;
