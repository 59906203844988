import Jenkins from '../img/icons/jenkins.png'
import Ansible from '../img/icons/ansible.png'
import aws from '../img/icons/aws.png'
import Linux from '../img/icons/linux.png'
import Docker from '../img/icons/docker.png'
import React from '../img/icons/react.png'
import JavaScript from '../img/icons/javascript.png'
import Html from '../img/icons/html.png'
import Css from '../img/icons/css.png'
import Mysql from '../img/icons/mysql.png'
import Firebase from '../img/icons/firebase.png'
import Gatsby from '../img/icons/gatsby.png'
import Git from '../img/icons/git.png'
import Mtcss from '../img/icons/mtcss.png'
import Sass from '../img/icons/sass.png'
import Node from '../img/icons/node.png'
import Express from '../img/icons/ex.png'
import Python from '../img/icons/py.png'
import Tailwind from '../img/icons/tailwind.png'
import NextJs from '../img/icons/next.png'
import MongoDb from '../img/icons/md.png'
import Angular from '../img/icons/angular.png'
import Java from '../img/icons/java.png'
import Spring from '../img/icons/spring.png'
import Rquery from '../img/icons/rq.png'
import Redux from '../img/icons/redux.png'
import Jwt from '../img/icons/jwt.png'
import MUI from '../img/icons/mui.png'
import Nginx from '../img/icons/nginx.png'
import Stripe from '../img/icons/stripe.webp'
import postgress from '../img/icons/postgress.png'
import ts from '../img/icons/ts.png'
import figma from '../img/icons/figma.png'
import framerMotion from '../img/icons/framer-motion.png'




export const TechIcons=[
    {
        id:"1",
        imgUrl:Html,
        name:"Html"
        ,
        category:"frontend",
        category2:"fullstack"

    },
    {
        id:"2",
        imgUrl:Css,
        name:"Css"
        ,
        category:"frontend",
        category2:"fullstack"

    },
    {
        id:"3.1",
        imgUrl:JavaScript,
        name:"JavaScript",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"3",
        imgUrl:React,
        name:"React",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"4",
        imgUrl:NextJs,
        name:"Next js",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"5",
        imgUrl:Gatsby,
        name:"Gatsby",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"6",
        imgUrl:Tailwind,
        name:"Tailwind",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"7",
        imgUrl:Sass,
        name:"Sass",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"8",
        imgUrl:Mysql,
        name:"MySql",
        category:"backend",
        category2:"fullstack"
    },
    {
        id:"9",
        imgUrl:Firebase,
        name:"Firebase",
        category:"backend",
        category2:"fullstack"
    },
    {
        id:"10",
        imgUrl:MongoDb,
        name:"MongoDb",
        category:"backend",
        category2:"fullstack"
    },
    {
        id:"11",
        imgUrl:Git,
        name:"Git",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"12",
        imgUrl:aws,
        name:"AWS",
        category:"other",
    },
    {
        id:"13",
        imgUrl:Linux,
        name:"Linux",
        category:"other",
    },
    {
        id:"14",
        imgUrl:Ansible,
        name:"Ansible",
        category:"other",
    },
    {
        id:"15",
        imgUrl:Jenkins,
        name:"Jenkins",
        category:"other",
    },
    {
        id:"16",
        imgUrl:Docker,
        name:"Docker",
        category:"other",
    },
    {
        id:"17",
        imgUrl:Python,
        name:"Python",
        category:"backend",
        category2:"fullstack"
    },
    {
        id:"18",
        imgUrl:Node,
        name:"Node",
        category:"backend",
        category2:"fullstack"
    },
    {
        id:"19",
        imgUrl:Express,
        name:"Express",
        category:"backend",
        category2:"fullstack"
    },{
        id:"20",
        imgUrl:Angular,
        name:"Angular",
        category:"frontend",
        category2:"fullstack",
    },
    {
        id:"21",
        imgUrl:Java,
        name:"Java",
        category:"backend",
        category2:"fullstack",
    },
    {
        id:"22",
        imgUrl:Spring,
        name:"Spring",
        category:"backend",
        category2:"fullstack",
    },
  
]


export const defaultSkills=[
    
    
    {
        id:"3.1",
        imgUrl:JavaScript,
        name:"JavaScript",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"3",
        imgUrl:React,
        name:"React",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"4",
        imgUrl:NextJs,
        name:"Next js",
        category:"frontend",
        category2:"fullstack"
    },
 
    {
        id:"6",
        imgUrl:Tailwind,
        name:"Tailwind",
        category:"frontend",
        category2:"fullstack"
    },
    
    {
        id:"8",
        imgUrl:Mysql,
        name:"MySql",
        category:"backend",
        category2:"fullstack"
    },
   
    
  
    {
        id:"12",
        imgUrl:aws,
        name:"AWS",
        category:"other",
    },
    
    
    {
        id:"15",
        imgUrl:Jenkins,
        name:"Jenkins",
        category:"other",
    },
    {
        id:"16",
        imgUrl:Docker,
        name:"Docker",
        category:"other",
    },
    
    {
        id:"18",
        imgUrl:Node,
        name:"Node",
        category:"backend",
        category2:"fullstack"
    },
    {
        id:"20",
        imgUrl:Angular,
        name:"Angular",
        category:"frontend",
        category2:"fullstack",
    }
  
];



export const TechStack=[
    {
        id:"1",
        imgUrl:Html,
        name:"Html"
        ,
        category:"frontend",
        category2:"fullstack"

    },
    {
        id:"2",
        imgUrl:Css,
        name:"Css"
        ,
        category:"frontend",
        category2:"fullstack"

    },
    {
        id:"3.1",
        imgUrl:JavaScript,
        name:"JavaScript",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"3",
        imgUrl:React,
        name:"React",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"3.11",
        imgUrl:Rquery,
        name:"ReactQuery",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"3.2",
        imgUrl:Redux,
        name:"Redux",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"3.3",
        imgUrl:ts,
        name:"ts",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"4",
        imgUrl:NextJs,
        name:"Next js",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"5",
        imgUrl:Gatsby,
        name:"Gatsby",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"6",
        imgUrl:Tailwind,
        name:"Tailwind",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"6.1",
        imgUrl:MUI,
        name:"Mui",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"6.2",
        imgUrl:Mtcss,
        name:"mtcss",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"7",
        imgUrl:Sass,
        name:"Sass",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"8",
        imgUrl:Mysql,
        name:"MySql",
        category:"backend",
        category2:"fullstack"
    },
    {
        id:"9",
        imgUrl:Firebase,
        name:"Firebase",
        category:"backend",
        category2:"fullstack"
    },
    {
        id:"10",
        imgUrl:MongoDb,
        name:"MongoDb",
        category:"backend",
        category2:"fullstack"
    },
    {
        id:"11",
        imgUrl:Git,
        name:"Git",
        category:"frontend",
        category2:"fullstack"
    },
    {
        id:"12",
        imgUrl:aws,
        name:"AWS",
        category:"other",
    },
    {
        id:"13",
        imgUrl:Linux,
        name:"Linux",
        category:"other",
    },
    {
        id:"14",
        imgUrl:Ansible,
        name:"Ansible",
        category:"other",
    },
    {
        id:"15",
        imgUrl:Jenkins,
        name:"Jenkins",
        category:"other",
    },
    {
        id:"16",
        imgUrl:Docker,
        name:"Docker",
        category:"other",
    },
    {
        id:"17",
        imgUrl:Python,
        name:"Python",
        category:"backend",
        category2:"fullstack"
    },
    {
        id:"18",
        imgUrl:Node,
        name:"Node",
        category:"backend",
        category2:"fullstack"
    },
    {
        id:"19",
        imgUrl:Express,
        name:"Express",
        category:"backend",
        category2:"fullstack"
    },{
        id:"20",
        imgUrl:Angular,
        name:"Angular",
        category:"frontend",
        category2:"fullstack",
    },
    {
        id:"21",
        imgUrl:Java,
        name:"Java",
        category:"backend",
        category2:"fullstack",
    },
    {
        id:"22",
        imgUrl:Spring,
        name:"Spring",
        category:"backend",
        category2:"fullstack",
    },
    {
        id:"23",
        imgUrl:Jwt,
        name:"Jwt",
        category:"backend",
        category2:"fullstack",
    },
    {
        id:"24",
        imgUrl:Nginx,
        name:"Nginx",
        category:"backend",
        category2:"fullstack",
    },
    {
        id:"25",
        imgUrl:framerMotion,
        name:"framer Motion",
        category:"backend",
        category2:"fullstack",
    },
    {
        id:"26",
        imgUrl:figma,
        name:"figma",
        category:"backend",
        category2:"fullstack",
    },
    {
        id:"27",
        imgUrl:Stripe,
        name:"stripe",
        category:"backend",
        category2:"fullstack",
    },
    {
        id:"28",
        imgUrl:postgress,
        name:"postgress",
        category:"backend",
        category2:"fullstack",
    },
  
]
