import React, { useEffect } from "react";

import Hero from "./Hero";
import Teams from "../pages/Teams";
import Testimonials from "../pages/Testimonials";
import About from "../pages/About";
import Work from "../pages/Work";
import Contact from "../pages/Contact";
import { useLocation } from "react-router-dom";


const Home = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <div className="pt-24  myMainContainer">
      <Hero />
      <About />
      <Work />
      <Teams />
      <Testimonials />
      <Contact />
    </div>
  );
};

export default Home;
