import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from './Navbar';

function ConditionalNavbar() {
  const location = useLocation();

  // Check if the current route path is /:name
  const hideNavbar = location.pathname === '/:name';
  // console.log(location,"location")
  // console.log(hideNavbar,"hidenavbar")

  // Render Navbar if hideNavbar is false (for all routes except /:name)
  return (
    <div>
      {!hideNavbar && <Navbar />}
      <Outlet />
    </div>
  );
}

export default ConditionalNavbar;
