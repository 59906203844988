import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../img/Logo.png'
import { useDispatch } from "react-redux";
import { logoutUser } from "../utils/authSlice";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [navBackground,setNavBackground] =useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

const changeNavBackground=()=>{
  if(window.scrollY >=150){
    setNavBackground(true)
  }else{
setNavBackground(false)
  }
}
window.addEventListener('scroll',changeNavBackground)
// console.warn(navBackground);

  const HandleNav = () => {
    setNav(!nav);
  };

  const handleLogout=()=>{
    dispatch(logoutUser())
    localStorage.removeItem("accessToken")
    localStorage.clear()
    navigate("/")
  }

  const isAuthenticated = localStorage.getItem("accessToken") !== null;
  
  return (
    <>
      <div className="fixed left-0 top-0 w-full z-10 ease-in duration-300 mb-10  ">
        <div className={` bg-black  border-b-2 border-green-500 m-auto flex justify-between items-center p-2 text-zinc-100  shadow-lg `}>
          <Link to="/">
            <img src={Logo} width={130} height={30} alt=" main logo" className="ml-4" />
          </Link>
          <ul className={`hidden sm:flex ${navBackground ? 'bg-black' : ''}`}>
            {isAuthenticated ? (
              <>
              <Link to="/" className="hover:text-green-500">
                  <li className="p-4 rounded-md text-zinc-100 hover:text-green-500 font-semibold hover:cursor-pointer">Home</li>
                </Link>
              <Link to="/admin" className="hover:text-green-500">
                  <li className="p-4 rounded-md text-zinc-100 hover:text-green-500 font-semibold hover:cursor-pointer">Dashboard</li>
                </Link>
               <Link to="/" onClick={handleLogout} className="hover:bg-green-600 bg-green-500 text-white rounded-md">
                <li className="p-4 rounded-lg text-zinc-100 font-semibold hover:cursor-pointer">Logout</li>
              </Link>
              </>
             
            ) : (
              <>
                <Link to="/" className="hover:text-green-500">
                  <li className="p-4 rounded-md text-zinc-100 hover:text-green-500 font-semibold hover:cursor-pointer">Home</li>
                </Link>
                <ScrollLink to="about" className="hover:text-green-500">
                  <li className="p-4 rounded-lg text-zinc-100 hover:text-green-500 font-semibold hover:cursor-pointer">About</li>
                </ScrollLink>
                <ScrollLink to="work" className="hover:text-green-500">
                  <li className="p-4 rounded-lg text-zinc-100 hover:text-green-500 font-semibold hover:cursor-pointer">Work</li>
                </ScrollLink>
                <Link to="/blogs">
                  <li className="p-4 rounded-lg text-zinc-100 hover:text-green-500 font-semibold hover:cursor-pointer">Blogs</li>
                </Link>
                <ScrollLink to="contact" className="hover:text-green-500">
                  <li className="p-4 rounded-lg text-zinc-100 hover:text-green-500 font-semibold hover:cursor-pointer">Contact</li>
                </ScrollLink>
                <Link to="/login" className="hover:bg-green-600 bg-green-500 text-white rounded-md">
                  <li className="p-4 rounded-lg text-zinc-100 font-semibold hover:cursor-pointer">Login</li>
                </Link>
              </>
            )}
          </ul>

          {/* Mobile Button */}
          <div onClick={HandleNav} className="block sm:hidden z-10 right-3">
            {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
          </div>
          {/* Mobile Menu */}
          <div
            className={
              nav
                ? "sm:hidden absolute top-0 left-0 right-10 bottom-0 flex justify-center items-center w-full h-screen bg-black text-center ease-in duration-300"
                : "sm:hidden absolute top-0 left-[-100%] right-10 bottom-0 flex justify-center items-center w-full h-screen bg-black text-center ease-in duration-300"
            }
          >
           <ul>
  {!isAuthenticated ? (
    <>
      <Link to="/" onClick={HandleNav}>
        <li className="text-4xl text-white hover:text-zinc-100 p-2">Home</li>
      </Link>
      <ScrollLink to="about" onClick={HandleNav}>
        <li className="text-4xl text-white hover-text-zinc-100 p-2">About</li>
      </ScrollLink>
      <ScrollLink to="work" onClick={HandleNav}>
        <li className="text-4xl text-white hover:text-zinc-100 p-2">Work</li>
      </ScrollLink>
      <Link to="/blogs" onClick={HandleNav}>
        <li className="text-4xl text-white hover:text-zinc-100 p-2">Blogs</li>
      </Link>
      <ScrollLink to="contact" onClick={HandleNav}>
        <li className="text-4xl text-white hover:text-zinc-100 p-2">Contact</li>
      </ScrollLink>
      <Link to="/login" onClick={HandleNav}>
        <li className="text-4xl text-white hover:text-zinc-100 p-2">Login</li>
      </Link>
    </>
  ) : (
    <>
    <Link to="/" onClick={HandleNav}>
      <li className="text-4xl text-white hover:text-zinc-100 p-2">Home</li>
    </Link>
    <Link to="/admin" onClick={HandleNav}>
      <li className="text-4xl text-white hover:text-zinc-100 p-2">Dashboard</li>
    </Link>
    <Link to="/" onClick={handleLogout} >
      <li className="text-4xl text-white hover:text-zinc-100 p-2">Logout</li>
    </Link>
    </>
    
  )}
</ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
